import { useCallback, useMemo, useState } from 'react'
import { Box, Text } from '@chakra-ui/layout'

import { getExtensionTypes, getFileExt, isBinaryFile } from 'utils/fileUtils'

import { useI18Context } from 'providers/i18Provider'
import { useKBotContext } from 'providers/KBotsProvider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useUploadContext } from 'providers/UploadProvider'

import { Banner } from './Banner'
import { ModalBox } from './Modal'
import { Uploader } from './Uploader'

type UploadWithPreviewProps = {
  allowMultipleFiles?: boolean
  botName: string
  isFileUploading: boolean
  isOpen: boolean
  kBotId?: string
  maxFileNameLength: number
  maxFileSize: string
  maxNumFiles: number
  onClose: () => void
  onError: (error: string) => void
  onSubmit: (files: File[]) => void
  supportedFileExtensions: Array<string>
  supportedMimeTypes: Array<string>
  uploadError: string
  uploadTokenMaxSize: number
}

export const UploadModal = ({
  botName,
  isFileUploading,
  isOpen,
  kBotId,
  maxFileNameLength,
  maxFileSize,
  maxNumFiles,
  onClose,
  onError,
  onSubmit,
  supportedFileExtensions,
  supportedMimeTypes,
  uploadError,
  uploadTokenMaxSize,
}: UploadWithPreviewProps) => {
  const { language, t } = useI18Context()
  const { docContents } = useUploadContext()
  const { getConversation, getCurrentConversationIDForBot } = useMessagesContext()
  const { kBotSelectionsDetails } = useKBotContext()

  const [isWarningAcknowledged, setIsWarningAcknowledged] = useState<boolean>(false)

  const currentConversationID = useMemo(() => {
    // "temp" is used as the default string value for a conversation that is yet to be mapped to a proper uuid
    return getCurrentConversationIDForBot(botName) ?? 'temp'
  }, [botName, getCurrentConversationIDForBot])

  const extensionTypes = useMemo(() => {
    return [...getExtensionTypes(supportedMimeTypes), ...supportedFileExtensions]
  }, [supportedMimeTypes, supportedFileExtensions])

  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat(language)
  }, [language])

  const onModalClose = useCallback(() => {
    // After the user closes the modal, reset the error message
    onError('')
    onClose()
  }, [onClose, onError])

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      onError('')

      const ext = getFileExt(acceptedFiles[0].type)
      const fileNameSplits = acceptedFiles[0].name.split('.')
      const fileExt = fileNameSplits[fileNameSplits.length - 1]

      if ((ext && extensionTypes.includes(ext)) || extensionTypes.includes(fileExt)) {
        // .ts file (typescript) will be recognized as video/mp2t, so we need to identify the content of the file
        let fileToSubmit = acceptedFiles[0]

        // ".ts" file (typescript) will be recognized as video/mp2t, so we need to identify the content of the file
        if (ext === 'ts') {
          const isBinary = isBinaryFile(await fileToSubmit.slice(0, fileToSubmit.size, fileToSubmit.type).arrayBuffer())
          // isBinary tells us if the file is a video of mime-type "video/mp2t"
          if (isBinary) {
            onError('unsupportedFileType')
            return
          }
        }

        // Need to set the type to "text/plain" to those files that can't be translated using the "mime" library
        if (supportedFileExtensions.includes(fileExt)) {
          // Create a new instance of the File type, specifically with type equaling "text/plain"
          // Need to create a new instance since the original file object is read-only
          const fileTemp = fileToSubmit.slice(0, fileToSubmit.size, fileToSubmit.type)
          fileToSubmit = new File([fileTemp], fileToSubmit.name, {
            type: 'text/plain',
            lastModified: fileToSubmit.lastModified,
          })
        }
        onSubmit([fileToSubmit])
      } else {
        onError('unsupportedFileType')
      }
    },
    [onError, extensionTypes, supportedFileExtensions, onSubmit]
  )

  const maxCharacterCount = useMemo(() => {
    let kBotUploadedCharacters = 0
    const currentConversation = getConversation(botName, currentConversationID)
    // Calculate the total number of characters used by the K-Bots File Content and User Instructions properties, and use this towards subtracting from the max upload character count
    if (currentConversation || kBotSelectionsDetails) {
      const fileContentLength =
        currentConversation?.fileContent?.length ?? kBotSelectionsDetails[botName]?.fileContent?.length ?? 0
      const userInstructionsLength =
        currentConversation?.userInstructions?.length ?? kBotSelectionsDetails[botName]?.userInstructions?.length ?? 0

      kBotUploadedCharacters += fileContentLength + userInstructionsLength
    }

    // (Max character count) - (K-Bot File Content count + K-Bot User Instructions count) - (Previously upload documents count)
    return (
      uploadTokenMaxSize * 4 -
      kBotUploadedCharacters -
      (docContents?.[botName]?.[kBotId ? kBotId : currentConversationID]?.characterCount || 0)
    )
  }, [botName, currentConversationID, docContents, getConversation, kBotId, kBotSelectionsDetails, uploadTokenMaxSize])

  const uploadErrorMessage = useMemo(() => {
    return t(`uploadModal.${uploadError}`, {
      maxFileNameLength,
      // If the uploadFileMaxSize is less than 1,000,000 then we are dealing with KB (e.g., 900,000 -> 900 KB). Otherwise we are dealing with MB (e.g., 20,000,000 -> 20 MB)
      maxFileSize,
      maxNumberTokens: numberFormat.format(maxCharacterCount),
    })
  }, [maxCharacterCount, maxFileNameLength, maxFileSize, numberFormat, t, uploadError])

  const uploadWarningBanner = useMemo(() => {
    return t('uploadModal.warningBanner', { returnObjects: true }) as string[]
  }, [t])

  return (
    <ModalBox
      disableCloseButton={isFileUploading}
      isOpen={isOpen}
      onClose={onModalClose}
      modalHeader={t('uploadModal.uploadCTA')}
      modalFooter={
        <Box>
          {uploadError && <Text className="mb-2 text-xs text-red-400">{uploadErrorMessage}</Text>}
          <Text className="text-xs">{t('uploadModal.disclaimer')}</Text>
        </Box>
      }
      modalBody={
        <>
          <Uploader
            botName={botName}
            banner={
              <Banner
                className="mb-4"
                onClick={() => setIsWarningAcknowledged(true)}
                title={t('uploadModal.warningBannerHeader')}
                description={
                  <Box className="last:mb-0">
                    {uploadWarningBanner.map((warning: string, warningIndex: number) => {
                      return (
                        <Text className="mb-2" key={`${warning}_${warningIndex}`}>
                          {warning}
                        </Text>
                      )
                    })}
                  </Box>
                }
                isVisible={!isWarningAcknowledged}
              />
            }
            customMetricsMessaging={
              uploadTokenMaxSize !== 0 && (
                <>
                  {t('uploadModal.maxCharacterCount')}:{' '}
                  <Text as="span" className="font-bold">
                    {numberFormat.format(maxCharacterCount)} {t('uploadModal.characters')}
                  </Text>
                </>
              )
            }
            extensionTypes={extensionTypes}
            isFileUploading={isFileUploading}
            maxFileSize={maxFileSize}
            maxNumFiles={maxNumFiles}
            onDrop={onDrop}
            uploadError={uploadError}
          />
        </>
      }
    />
  )
}
