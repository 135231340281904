import { Dispatch, SetStateAction } from 'react'

type ReplaceIdPayloadBotSpecific<T> = {
  botName: string
  newConversationId: string
  oldConversationId: string
  setState: Dispatch<SetStateAction<Record<string, Record<string, T>>>>
}

export const replaceIdInRecordBotSpecific = <T>(payload: ReplaceIdPayloadBotSpecific<T>) => {
  const { botName, newConversationId, oldConversationId, setState } = payload

  const updateState = <T>(setState: Dispatch<SetStateAction<Record<string, Record<string, T>>>>) => {
    setState((prevState) => {
      const botSpecificState = prevState[botName]

      if (!botSpecificState || !(oldConversationId in botSpecificState)) {
        return prevState // Return the same state if the bot or old conversationId doesn't exist
      }

      // Get all data for the old conversationId
      const oldConversationIdData = botSpecificState[oldConversationId]

      // Create a new bot conversation object with the new conversationId
      const updatedConversationIdData = {
        ...botSpecificState,
        [newConversationId]: oldConversationIdData, // Assign the data to the new conversationId
      }

      // Delete the old conversationId
      delete updatedConversationIdData[oldConversationId]

      // Return the updated state
      return {
        ...prevState,
        [botName]: updatedConversationIdData,
      }
    })
  }

  // Update conversationSettings
  updateState<T>(setState)
}

type ReplaceIdPayload<T> = {
  newConversationId: string
  oldConversationId: string
  setState: Dispatch<SetStateAction<Record<string, T>>>
}

export const replaceIdInRecord = <T>(payload: ReplaceIdPayload<T>) => {
  const { newConversationId, oldConversationId, setState } = payload

  const updateState = <T>(setState: Dispatch<SetStateAction<Record<string, T>>>) => {
    setState((prevState) => {
      if (!(oldConversationId in prevState)) {
        return prevState // Return the same state if oldConversationId doesn't exist
      }

      // Get the content for the old conversationId
      const oldConversationIdData = prevState[oldConversationId]

      // Create the new state with the new conversationId
      const updatedConversationIdData = {
        ...prevState,
        [newConversationId]: oldConversationIdData, // Assign the content to the new conversationId
      }

      // Delete the old conversationId
      delete updatedConversationIdData[oldConversationId]

      // Return the updated docContents state
      return updatedConversationIdData
    })
  }

  // Update conversationSettings
  updateState<T>(setState)
}
