import { Box, Text } from '@chakra-ui/layout'
import cx from 'classnames'
import htmlReactParser from 'html-react-parser'

import { documentsMatchInString } from 'utils/documentUtils/documentsMatchInString'
import { sanitize } from 'utils/htmlSanitize'

type HTMLStringParserProps = {
  htmlString: string
  getDocumentName?: (document: string) => {
    id: string
    label: string
  }
  className?: string
}

export const HTMLStringParser = ({ htmlString, getDocumentName, className }: HTMLStringParserProps) => {
  if (!htmlString) return null

  const contextList = documentsMatchInString(htmlString)

  if (!contextList) {
    return <span>return</span>
  }

  return (
    <Box>
      {contextList.map((item, index) => {
        // Sanitize the HTML
        const sanitizedContext = sanitize(item.text)

        // Use html-react-parser to parse the sanitized html string into React elements
        const textToRender = htmlReactParser(sanitizedContext)

        return (
          <Box key={`${item.text}__${index}`} className={`${contextList.length > 1 && 'mt-5'}`}>
            {'document' in item && getDocumentName && (
              <Text className="text-xs font-bold">{getDocumentName(item.document).label}</Text>
            )}
            <Box className={`html-string-container ${cx('text-xs', className)}`}>{textToRender}</Box>
          </Box>
        )
      })}
    </Box>
  )
}
