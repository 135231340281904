import { createContext, useContext, useEffect, useState } from 'react'
import { usePDF } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'

import { ConversationPDFDocument } from '../components/pdfs/ConversationPDFDocument'
import { useEventLogger } from '../hooks/useEventLogger'
import { ChatMessage, ChildrenProps, DisclaimerProperty } from '../types/types'
import { formatLastUpdatedDate } from '../utils/formatter'

import { useI18Context } from './i18Provider'

type PDFContextType = {
  isRequestingPDF: boolean
  exportConversationPdf: (botName: string, messages: ChatMessage[], disclaimer: DisclaimerProperty[]) => void
}

export const PDFContext = createContext<PDFContextType>({
  isRequestingPDF: false,
  exportConversationPdf: () => {
    // placeholder
  },
})

export const PDFProvider = ({ children }: ChildrenProps) => {
  const { doesTranslationExist, getTForNS, language, t } = useI18Context()
  const { logUIErrorEvent } = useEventLogger()

  const [isRequestingPDF, setIsRequestingPDF] = useState(false)
  const [botRequesting, setBotRequesting] = useState<string | null>(null)

  const [pdf, update] = usePDF({})

  useEffect(() => {
    if (!pdf.loading && !pdf.error && pdf.blob && pdf.url && botRequesting) {
      const botT = getTForNS(botRequesting.toLocaleLowerCase())
      setIsRequestingPDF(true)
      saveAs(pdf.blob, botT('header'))
      setIsRequestingPDF(false)
      setBotRequesting(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf, botRequesting, t])

  const exportConversationPdf = (botName: string, messages: ChatMessage[], disclaimer: DisclaimerProperty[]) => {
    if (messages.length) {
      try {
        const botT = getTForNS(botName.toLocaleLowerCase())
        const newDate = new Date()
        // newDate.getMonth() returns the month as a zero-based index, need to do "+ 1"
        const date = formatLastUpdatedDate(
          `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`,
          language
        )

        // If the Bot Configuration translations do not include a "disclaimer" section, don't try and return an empty disclaimer
        const disclaimerStrings: DisclaimerProperty[] = disclaimer.reduce(
          (accumulator: DisclaimerProperty[], property: DisclaimerProperty) => {
            let transformedProperty: DisclaimerProperty | undefined

            if (property.type === 'title' && doesTranslationExist(property.value, botName.toLocaleLowerCase())) {
              transformedProperty = { type: 'title', value: botT(property.value) }
            } else if (property.type === 'text' && doesTranslationExist(property.value, botName.toLocaleLowerCase())) {
              transformedProperty = { type: 'text', value: botT(property.value) }
            } else if (
              property.type === 'link' &&
              doesTranslationExist(property.value.text, botName.toLocaleLowerCase()) &&
              doesTranslationExist(property.value.url, botName.toLocaleLowerCase())
            ) {
              transformedProperty = {
                type: 'link',
                value: {
                  text: botT(property.value.text),
                  url: botT(property.value.url),
                },
              }
            }

            // Only push the transformed property if it was successfully transformed
            if (transformedProperty) {
              accumulator.push(transformedProperty)
            }

            return accumulator
          },
          []
        )

        setBotRequesting(botName)
        update(
          <ConversationPDFDocument
            botName={botT('header')}
            messages={messages}
            date={date}
            disclaimer={disclaimerStrings}
            t={t}
          />
        )
      } catch (e) {
        logUIErrorEvent({
          error: e as Error,
          errorMessage: 'pdf-generation-error',
          bot: botName,
        })
      }
    }
  }

  return <PDFContext.Provider value={{ isRequestingPDF, exportConversationPdf }}>{children}</PDFContext.Provider>
}

export const usePDFContext = (): PDFContextType => useContext(PDFContext)
