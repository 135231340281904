import { useEffect, useMemo, useState } from 'react'
import { IconType } from 'react-icons'
import { Image as ChakraImage } from '@chakra-ui/image'
import { SkeletonCircle } from '@chakra-ui/skeleton'

import { useImageContext } from 'providers/ImageProvider'
import { useSettingsContext } from 'providers/SettingsProvider'

type BotIconWithFallbackProps = {
  alt: string
  botName: string
  botType: string
  className: string
  fallbackContent: string | IconType
}

const BotIconWithFallback: React.FC<BotIconWithFallbackProps> = ({
  botName,
  botType,
  alt,
  fallbackContent,
  className,
}) => {
  const { isLightMode } = useSettingsContext()
  const { getImageBase64 } = useImageContext()

  // local state is used to prevent race conditions in relation to rendering
  const [localImageBase64, setLocalImageBase64] = useState<string | null | undefined>(null)

  // we check if iconType is defined in the config,
  // if it is defined, then we don't fetch custom image, otherwise we do
  const isIconType = useMemo(() => {
    return typeof fallbackContent !== 'string'
  }, [fallbackContent])

  // custom image in each bot must be named exactly as "botIconBlack" or "botIconWhite"
  const customImageSrc = useMemo(() => {
    if (isLightMode) {
      return `${botName.toLocaleLowerCase()}/assets/botIconBlack.png`
    } else {
      return `${botName.toLocaleLowerCase()}/assets/botIconWhite.png`
    }
  }, [isLightMode, botName])

  useEffect(() => {
    // if iconType is false (not defined in the config), then we go ahead and fetch custom image
    // if botType is 'general', then we skip fetch and go straight to the fallback, since it uses the robot image by default
    if (!isIconType && botType.toLocaleLowerCase() !== 'general') {
      const base64Image = getImageBase64(customImageSrc)
      setLocalImageBase64(base64Image)
    }
  }, [botType, customImageSrc, getImageBase64, isIconType])

  // undefined = image is in the loading state
  if (localImageBase64 === undefined) {
    return <SkeletonCircle className={className} />
  }

  // null = image is in fallback state
  // fallback content can either be a string (image path) or an icon
  if (localImageBase64 === null) {
    if (typeof fallbackContent === 'string') {
      return <ChakraImage alt="Robot" src={fallbackContent} className={className} />
    } else {
      const FallbackIcon = fallbackContent as IconType
      return <FallbackIcon className={className} />
    }
  }

  // otherwise - displaying custom bot image
  return <ChakraImage alt={alt} src={localImageBase64} className={className} />
}

export { BotIconWithFallback }
