import { KeyboardEvent } from 'react'
import { Navigate, Route, Routes as RouterRoutes, useLocation } from 'react-router-dom'
import { Box, Text } from '@chakra-ui/layout'

import { useSettingsContext } from 'providers/SettingsProvider'

import { TabsType } from 'types/types'

export const Tab = ({
  handleTabClick,
  parentRouteSection,
  tabSection,
}: {
  handleTabClick: (route: string) => void
  parentRouteSection: string
  tabSection: TabsType
}) => {
  const location = useLocation()
  const { isLightMode } = useSettingsContext()

  return (
    <Box
      onClick={() => handleTabClick(tabSection.route)}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
          handleTabClick(tabSection.route)
        }
      }}
      tabIndex={0}
      className={`flex items-center justify-center h-full font-bold px-3 py-1.5 text-sm lg:py-2 lg:px-4 lg:text-base border border-white rounded-t mr-1 cursor-pointer ${location.pathname === `/${parentRouteSection}${tabSection.route}` ? 'bg-white text-kpmgBlue' : isLightMode ? 'hover:bg-[#3182CE]/[.5]' : 'hover:bg-[#62B3ED]/[.5]'}`}
    >
      <Text>{tabSection.label}</Text>
    </Box>
  )
}

export const TabPanels = ({ tabSections, defaultTab }: { defaultTab: string; tabSections: TabsType[] }) => {
  return (
    <Box className="flex-grow h-full overflow-hidden border border-white rounded-b-md">
      <RouterRoutes>
        {defaultTab && <Route path="/" element={<Navigate to={defaultTab} />} />}
        {tabSections.map((tabSection) => {
          return (
            <Route
              key={tabSection.route}
              path={tabSection.route}
              element={<Box className="flex flex-col h-full p-2">{tabSection.component}</Box>}
            />
          )
        })}
      </RouterRoutes>
    </Box>
  )
}
