import { useMemo } from 'react'
import { IconType } from 'react-icons'
import * as BiIcons from 'react-icons/bi'
import { Image } from '@chakra-ui/image'
import { Box, BoxProps, Text } from '@chakra-ui/layout'
import { DualLanguageOptionalValues, KBotBaseConfig } from '@kleo/types'

import { useI18Context } from 'providers/i18Provider'
import { useSettingsContext } from 'providers/SettingsProvider'
import { useThemeContext } from 'providers/ThemeProvider'

type KBotCardProps = { kBot: KBotBaseConfig; onClick: () => void } & Pick<BoxProps, 'className'>

export const KBotCard = ({ kBot, className, onClick }: KBotCardProps) => {
  const { date, description, template } = kBot
  const { isLightMode } = useSettingsContext()
  const { isTablet } = useThemeContext()
  const { t, language, languageAbbreviation } = useI18Context()

  const formattedDate = useMemo(() => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: '2-digit' }
    return new Intl.DateTimeFormat(language, options).format(new Date(date))
  }, [date, language])

  const CardIcon = useMemo(() => {
    let icon: IconType | string = ''
    if ('icon' in kBot.template) {
      const BiIcon = kBot.template.icon && (BiIcons as Record<string, IconType>)[kBot.template.icon]
      // Check if the component exists in the 'react-icons' library
      if (BiIcon) {
        icon = BiIcon
      } else {
        icon = isLightMode ? 'images/robotBlack.png' : 'images/robotWhite.png'
      }
    } else {
      icon = isLightMode ? 'images/robotBlack.png' : 'images/robotWhite.png'
    }
    return icon
  }, [isLightMode, kBot])

  return (
    <Box
      tabIndex={0}
      onClick={onClick}
      className={`${className} w-full flex flex-col hover:cursor-pointer overflow-y-hidden p-3 rounded-lg shadow-lg ${isLightMode ? 'bg-white border-gray-50 hover:bg-gray-100' : 'bg-kpmgDarkBlue text-white hover:bg-kpmgDarkBlueHover'}`}
      style={{ minHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} // This ensures items are spaced from top to bottom
    >
      <Box className="flex flex-row items-center gap-3">
        {typeof CardIcon === 'string' ? (
          <Image alt="" src={CardIcon} className={isTablet ? 'h-8 w-8' : 'h-6 w-6'} />
        ) : (
          <CardIcon className={isTablet ? 'h-8 w-8' : 'h-6 w-6'} />
        )}
        {/* Issues around adding break-words as a Tailwind class to className */}
        <Text className="text-sm font-bold md:text-base" style={{ wordBreak: 'break-word' }}>
          {template[languageAbbreviation]}
        </Text>
      </Box>

      {/* Middle content that can grow */}
      <Box className="relative flex-grow mt-1 overflow-hidden overflow-ellipsis line-clamp-4">
        <Text className="text-xs">
          {description?.map((desc: DualLanguageOptionalValues, descIndex: number) =>
            languageAbbreviation in desc && desc[languageAbbreviation] && !!desc[languageAbbreviation].length ? (
              <Text as="span" key={`${desc[languageAbbreviation]}_${descIndex}`}>
                {desc[languageAbbreviation]}{' '}
              </Text>
            ) : (
              <Text as="span" key={`${desc[languageAbbreviation]}_${descIndex}`} className="italic">
                {t('kBots.noDescription')}
              </Text>
            )
          )}
        </Text>
      </Box>

      {/* Date box forced to bottom */}
      <Box className="flex justify-end mt-2">
        <Box className={`px-1 py-0.5 rounded border-2 ${isLightMode ? 'border-gray-300' : 'border-gray-600'}`}>
          <Text className={`text-xs font-bold ${isLightMode ? 'text-gray-600' : 'text-gray-400'}`}>
            {formattedDate}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}
