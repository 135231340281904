import { ChartData } from 'types/types'

export const chartDataExtractor = <T extends ChartData>(content: string): T | false => {
  const cleanedString = content
    .replace(/'/g, '"')
    .replace(/(\w+)(?=:)/g, '"$1"')
    .replace(/(\w+)(?=\s*:\s*[{|[])/g, '"$1"')
    .replace(/:\s*{/g, ':{')
    .replace(/:\s*\[/g, ':[')

  try {
    const convertedArray: T = JSON.parse(cleanedString)
    if (Array.isArray(convertedArray) && convertedArray.length === 0) {
      return false
    } else {
      // At this point the converted array is a single JSON of table data.
      // We need to check if the table JSON has data and return false if it has no rows or headers
      for (const entry of Object.values(convertedArray)) {
        if (Array.isArray(entry) && entry.length === 0) {
          return false
        }
      }
    }

    // Output the array value
    return convertedArray
  } catch (e) {
    return false
  }
}

export const defaultMessageProperties = {
  line: {
    isFetching: false,
    isGenerateError: false,
    isAPIError: false,
    isTimeoutError: false,
    isDataError: false,
  },
  bar: {
    isFetching: false,
    isGenerateError: false,
    isAPIError: false,
    isTimeoutError: false,
    isDataError: false,
  },
  pie: {
    isFetching: false,
    isGenerateError: false,
    isAPIError: false,
    isTimeoutError: false,
    isDataError: false,
  },
  table: {
    isFetching: false,
    isGenerateError: false,
    isAPIError: false,
    isTimeoutError: false,
    isDataError: false,
  },
}
