import { KeyboardEvent, useMemo } from 'react'
import { IconType } from 'react-icons'
import { BiCoin, BiDollar, BiFont, BiUser } from 'react-icons/bi'
import { Box, Text } from '@chakra-ui/layout'
import { MenuItem } from '@chakra-ui/menu'
import { Skeleton } from '@chakra-ui/skeleton'

import { useI18Context } from 'providers/i18Provider'
import { useUserProfileContext } from 'providers/UserProfileProvider'

import { IconButton } from './buttons/IconButton'
import { ModalBox } from './Modal'

type UserProfileProps = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  type?: 'menu' | 'icon'
}

export const UserProfile = ({ isOpen, onClose, onOpen, type = 'icon' }: UserProfileProps) => {
  const { language, t } = useI18Context()
  const { isFetchingUsageData, setShouldFetchUsageData, usageData, usageDataError } = useUserProfileContext()

  // Need to wrap this in a useMemo, otherwise calling "new Intl.NumberFormat" consecutively causes the site to lag
  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat(language)
  }, [language])

  const getCurrentMonthYear = useMemo(() => {
    const now = new Date()
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' }
    return new Intl.DateTimeFormat(language, options).format(now)
  }, [language])

  return (
    <>
      {type === 'menu' ? (
        <MenuItem
          tabIndex={0}
          icon={<BiUser className="text-lg" />}
          onClick={() => {
            setShouldFetchUsageData(true)
            onOpen()
          }}
          onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
            if (event.key === 'Enter') {
              setShouldFetchUsageData(true)
              onOpen()
            }
          }}
        >
          {t('userProfile.header')}
        </MenuItem>
      ) : (
        <IconButton
          aria-label="user-profile"
          className="ml-4"
          iconClassName="text-2xl"
          iconName={BiUser}
          onClick={() => {
            setShouldFetchUsageData(true)
            onOpen()
          }}
          test-id="user-profile-button"
          variant="outline"
        />
      )}
      <ModalBox
        // hasAutoWidth allows the Modal to not be limited in width, and can properly show all content without wrapping
        hasAutoWidth
        isOpen={isOpen}
        onClose={onClose}
        modalHeader={t('userProfile.header')}
        modalBody={
          <Box>
            <Text className="mb-4">{t('userProfile.usage', { monthYear: getCurrentMonthYear })}</Text>
            {isFetchingUsageData ? (
              <>
                <Box className="flex flex-col grid-cols-2 gap-3 md:grid">
                  <Skeleton className="h-16 rounded-lg min-w-52" />
                  <Skeleton className="h-16 rounded-lg min-w-52" />
                  <Skeleton className="h-16 rounded-lg min-w-52" />
                  <Skeleton className="h-16 rounded-lg min-w-52" />
                </Box>
                <Box className="mt-2">
                  <Skeleton className="h-4" />
                </Box>
              </>
            ) : (usageData && 'errorList' in usageData) || usageDataError ? (
              <Text>{t('userProfile.usageDataError')}</Text>
            ) : usageData ? (
              <Box>
                <Box className="flex flex-col grid-cols-2 gap-3 md:grid">
                  <ProfileCard
                    colour="blue"
                    contentText={`${numberFormat.format(usageData.translatedChars)}`}
                    headerText={t('userProfile.translationCharacters')}
                    Icon={BiFont}
                  />
                  <ProfileCard
                    colour="green"
                    contentText={usageData.translationCost.toLocaleString(language, {
                      style: 'currency',
                      currency: 'CAD',
                    })}
                    hasAsterisk
                    headerText={t('userProfile.translationCost')}
                    Icon={BiDollar}
                  />
                  <ProfileCard
                    colour="yellow"
                    contentText={numberFormat.format(usageData.reqToken + usageData.resToken)}
                    headerText={t('userProfile.tokens')}
                    Icon={BiCoin}
                  />
                </Box>
                <Text className="mt-2 text-xs">
                  <Text as="span" className="text-red-600">
                    *
                  </Text>
                  <Text as="span"> {t('userProfile.disclaimer')}</Text>
                </Text>
              </Box>
            ) : (
              <Text>{t('userProfile.usageDataError')}</Text>
            )}
          </Box>
        }
      />
    </>
  )
}

type ProfileCardType = {
  colour: string
  contentText: string
  hasAsterisk?: boolean
  headerText: string
  Icon: IconType
}

const ProfileCard = ({ colour, contentText, hasAsterisk = false, headerText, Icon }: ProfileCardType) => {
  // All of the below class mappings are required because content will not render properly when using bg-${colour}-${number} within className
  const bgColorClass = {
    blue: 'bg-blue-100',
    green: 'bg-green-100',
    yellow: 'bg-yellow-100',
  }[colour]

  const outerCircleClass = {
    blue: 'bg-blue-200',
    green: 'bg-green-200',
    yellow: 'bg-yellow-200',
  }[colour]

  const innerCircleClass = {
    blue: 'bg-blue-300',
    green: 'bg-green-300',
    yellow: 'bg-yellow-300',
  }[colour]

  return (
    <Box className={`relative p-2 overflow-hidden ${bgColorClass} rounded-lg shadow-md`}>
      <Box className="relative z-10 mb-1 text-sm">
        <Box className="flex items-center">
          <Box className="flex items-center justify-center w-5 h-5 mr-2 bg-black rounded-sm bg-opacity-30">
            <Icon className="w-full h-full p-0.5 text-white" />
          </Box>
          <Text className="text-gray-800">
            {headerText}
            {hasAsterisk && (
              <Text as="span" className="text-red-700">
                {' '}
                *
              </Text>
            )}
          </Text>
        </Box>
      </Box>
      <Text as="h2" className="relative z-10 text-lg font-bold text-gray-800 md:text-xl">
        {contentText}
      </Text>
      <Box
        className={`absolute bottom-0 right-0 w-20 h-20 ${outerCircleClass} rounded-full z-0`}
        style={{ transform: 'translate(60%, 60%)' }}
      />
      <Box
        className={`absolute bottom-0 right-0 w-16 h-16 ${innerCircleClass} rounded-full z-0`}
        style={{ transform: 'translate(60%, 60%)' }}
      />
    </Box>
  )
}
