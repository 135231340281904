import { createContext, ReactNode, useCallback, useContext, useState } from 'react'

type ImageContextProps = {
  getImageBase64: (imagePath: string) => string | undefined | null
}

const ImageContext = createContext<ImageContextProps>({} as ImageContextProps)

export const ImageProvider = ({ children }: { children: ReactNode }) => {
  // only reserved for small size images, large size not recommended
  // undefined = initial, string = found, null = not found
  const [imageCache, setImageCache] = useState<Record<string, string | undefined | null>>({})

  const updateImageCache = useCallback((imagePath: string, base64: string | undefined | null): void => {
    setImageCache((prevCache) => ({
      ...prevCache,
      [imagePath]: base64,
    }))
  }, [])

  const fetchImageBase64 = useCallback(
    async (imagePath: string) => {
      updateImageCache(imagePath, undefined)

      try {
        const response = await fetch(imagePath)

        if (!response.ok) {
          throw new Error('failed to fetch image')
        }

        const blob = await response.blob()
        const reader = new FileReader()

        reader.onloadend = () => {
          updateImageCache(imagePath, reader.result as string)
        }

        reader.readAsDataURL(blob)
      } catch (error) {
        updateImageCache(imagePath, null)
      }
    },
    [updateImageCache]
  )

  const getImageBase64 = useCallback(
    (imagePath: string) => {
      if (!(imagePath in imageCache)) {
        fetchImageBase64(imagePath)
      }

      return imageCache[imagePath]
    },
    [fetchImageBase64, imageCache]
  )

  return (
    <>
      <ImageContext.Provider
        value={{
          getImageBase64,
        }}
      >
        {children}
      </ImageContext.Provider>
    </>
  )
}

export const useImageContext = (): ImageContextProps => useContext(ImageContext)
