import { ReactNode, useEffect, useState } from 'react'
import { Box, Heading } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'

import { useI18Context } from 'providers/i18Provider'
import { useThemeContext } from 'providers/ThemeProvider'

import { useSettingsContext } from '../providers/SettingsProvider'

import { TextButton } from './buttons/TextButton'

type ModalBoxProps = {
  closeOnEscape?: boolean
  closeOnOverlayClick?: boolean
  customHeaderContent?: React.ReactNode
  disableCloseButton?: boolean
  hasAutoWidth?: boolean
  hasFullWidth?: boolean
  hasOverflowedBody?: boolean
  hideCloseButton?: boolean
  isOpen: boolean
  modalBody: React.ReactNode
  modalFooter?: React.ReactNode
  modalHeader: string
  modalHeaderClass?: string
  onClose: () => void
  showHeaderDivider?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full'
  subHeaders?: React.ReactNode
}

export const ModalBox = (props: ModalBoxProps) => {
  const {
    closeOnEscape = true,
    closeOnOverlayClick = false,
    customHeaderContent,
    disableCloseButton = false,
    hasAutoWidth,
    hasFullWidth,
    hasOverflowedBody = false,
    hideCloseButton = false,
    isOpen,
    modalBody,
    modalFooter,
    modalHeader,
    modalHeaderClass = 'text-lg',
    onClose,
    showHeaderDivider = false,
    size = 'md',
    subHeaders,
  } = props

  const { isDesktop, isTablet } = useThemeContext()

  const handleDisabledClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    // if it's not disabled, close it, else do nothing
    if (!disableCloseButton) {
      onClose()
    }
  }

  const modalContentClass = hasAutoWidth
    ? {
        width: hasFullWidth ? 'full' : 'auto',
        maxWidth: isDesktop ? '66.6667%' : isTablet ? '75%' : '91.6667%', // simulating width-layout
      }
    : {}

  return (
    <Modal
      closeOnEsc={closeOnEscape}
      closeOnOverlayClick={closeOnOverlayClick}
      isOpen={isOpen}
      onClose={onClose}
      size={size}
    >
      <ModalOverlay />
      <ModalContent className="mx-4" sx={modalContentClass}>
        <ModalHeader
          test-id="modal-header"
          className={`${!hideCloseButton && 'pr-12'} text-base md:text-lg ${showHeaderDivider && 'border-b border-b-kpmgGray45'}`}
        >
          <Box className="flex flex-row flex-wrap items-center justify-between gap-2">
            <Heading className={modalHeaderClass} tabIndex={0}>
              {modalHeader}
            </Heading>
            <Box className={`${!hideCloseButton && 'pr-2'}`}>{customHeaderContent}</Box>
          </Box>
          {subHeaders}
        </ModalHeader>
        {!hideCloseButton && (
          <ModalCloseButton
            onClick={handleDisabledClose}
            _disabled={disableCloseButton ? { cursor: 'not-allowed', opacity: 0.4 } : {}}
            isDisabled={disableCloseButton}
          />
        )}
        <ModalBody className={`text-sm md:text-base ${hasOverflowedBody && 'overflow-y-auto'}`}>{modalBody}</ModalBody>
        <ModalFooter className="pt-2">{modalFooter}</ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type ModalFooterProps = {
  closeOnPrimaryClick?: boolean
  delay?: number
  isDisabled?: boolean
  onClose: () => void
  primaryButtonLabel: ReactNode
  primaryButtonOnClick: () => void
}

export const ModalConfirmCancelFooter = ({
  closeOnPrimaryClick = true,
  delay,
  isDisabled = false,
  onClose,
  primaryButtonLabel,
  primaryButtonOnClick,
}: ModalFooterProps) => {
  const { t } = useI18Context()
  const { isLightMode } = useSettingsContext()

  const confirmOnClick = () => {
    primaryButtonOnClick()
    !!closeOnPrimaryClick && onClose()
  }

  return (
    <Box className="flex flex-wrap justify-end gap-3">
      <TextButton
        aria-label="close"
        className="text-sm md:text-base max-w-min"
        id="close-button"
        isDisabled={isDisabled}
        onClick={onClose}
      >
        {t('controls.cancel')}
      </TextButton>
      {delay ? (
        <CountdownButton
          delay={delay}
          primaryButtonOnClick={primaryButtonOnClick}
          primaryButtonLabel={primaryButtonLabel}
        />
      ) : (
        <TextButton
          aria-label="next"
          className={`text-sm md:text-base ${
            isLightMode
              ? 'clear-messages-modal-button text-white bg-kpmgCobaltBlue hover:bg-kpmgCobaltBlueHover'
              : 'text-black bg-white hover:bg-whiteHover'
          }`}
          id="next-button"
          isDisabled={isDisabled}
          onClick={confirmOnClick}
        >
          {primaryButtonLabel}
        </TextButton>
      )}
    </Box>
  )
}

const CountdownButton = ({
  delay,
  primaryButtonOnClick,
  primaryButtonLabel,
}: Pick<ModalFooterProps, 'primaryButtonOnClick' | 'primaryButtonLabel'> & { delay: number }) => {
  const { isLightMode } = useSettingsContext()

  const [seconds, setSeconds] = useState(delay)
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setTimeout(() => setSeconds(seconds - 1), 1000)
      return () => clearTimeout(timerId)
    } else {
      setIsDisabled(false)
    }
  }, [seconds])

  return (
    <TextButton
      className={`text-sm md:text-base ${
        isLightMode
          ? 'clear-messages-modal-button text-white bg-kpmgCobaltBlue hover:bg-kpmgCobaltBlueHover'
          : 'text-black bg-white hover:bg-whiteHover'
      }`}
      aria-label="next"
      id="next-button"
      onClick={primaryButtonOnClick}
      isDisabled={isDisabled}
    >
      {isDisabled ? seconds : primaryButtonLabel}
    </TextButton>
  )
}
