import { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { InteractionStatus, IPublicClientApplication } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Text } from '@chakra-ui/layout'
import { t } from 'i18next'

import { TextButton } from 'components/buttons/TextButton'

import { FetchTokenError } from 'utils/appError'

import { ModalBox } from '../components/Modal'
import { ChildrenProps } from '../types/types'

import { useConfigContext } from './ConfigurationProvider'
import { useSettingsContext } from './SettingsProvider'

type AuthContextType = {
  areTermsAccepted: boolean
  getToken(): Promise<string>
  setAreTermsAccepted: Dispatch<SetStateAction<boolean>>
  inProgress: InteractionStatus
  msalInstance: IPublicClientApplication
  isAuthenticated: boolean
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export function AuthProvider({ children }: ChildrenProps): JSX.Element {
  const { INACTIVITY_TIMEOUT } = useConfigContext()
  const { instance: msalInstance, accounts, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const { isLightMode } = useSettingsContext()

  const [token, setToken] = useState<{ tokenId: string; expiry: number } | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [areTermsAccepted, setAreTermsAccepted] = useState<boolean>(false)

  // This function will run every time we make an API call to check if the access token is still valid before making the API request
  const getToken = useCallback(async (): Promise<string> => {
    // This function is responsible for acquiring a token
    const fetchToken = async () => {
      try {
        const result = await msalInstance.acquireTokenSilent({
          scopes: [''],
          account: accounts[0],
        })
        const newExpiration = result.account.idTokenClaims?.exp
          ? result.account.idTokenClaims.exp * 1000
          : Date.now() + 30 * 60 * 1000 // Fallback to 30 minutes if "exp" is undefined
        return { tokenId: result.idToken, expiry: newExpiration }
      } catch {
        try {
          // If the acquireTokenSilent() method fails with an error, run the acquireTokenRedirect() method to prompt the user to sign in again.
          // The acquireTokenRedirect() method will redirect the user to the login page of the identity provider, where they can enter their credentials and obtain a new token.
          msalInstance.acquireTokenRedirect({
            scopes: [''],
          })
        } catch {
          // If acquireTokenRedirect failed, force the user to logout
          msalInstance.logout()
        }
        return null
      }
    }

    // The token is not a string and we aren't currently acquiring a token
    // Calculate whether the expirationTime of the token exceeds the current time
    if (!token || new Date(token.expiry) <= new Date()) {
      const fetchedToken = await fetchToken()
      setToken(fetchedToken)
      if (!fetchedToken) throw new FetchTokenError('Unable to fetch token')
      return fetchedToken.tokenId
    }

    return token.tokenId
  }, [accounts, msalInstance, token])

  // Logic for logging out a user if they are idle
  const timeout: number = INACTIVITY_TIMEOUT * 60_000 || 60 * 60_000 // fallback to 60 minutes
  const [remaining, setRemaining] = useState<number>(300) // default remaining time is 5 minutes (300s)
  const [promptTimer, setPromptTimer] = useState<null | NodeJS.Timer>(null)

  const handleOnIdle = () => {
    if (isAuthenticated) {
      msalInstance.logout()
    }
  }

  const onPrompt = () => {
    setPromptTimer(
      setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }, 1000)
    )
    onOpen()
  }

  const { getRemainingTime, activate } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
    onPrompt,
    promptBeforeIdle: 5 * 60_000, // show the warning 5 minutes before forced logout
  })

  const handleClose = () => {
    promptTimer && clearInterval(promptTimer as unknown as number)
    onClose()
  }

  return (
    <>
      <AuthContext.Provider
        value={{ areTermsAccepted, getToken, setAreTermsAccepted, msalInstance, isAuthenticated, inProgress }}
      >
        <ModalBox
          isOpen={isOpen}
          modalBody={
            <Box>
              <Text as="h2" className="mb-2 font-bold">
                {t('sessionModal.logoutWarning')}
                {remaining > 0
                  ? ` ${Math.floor(remaining / 60)}:${(remaining % 60).toString().padStart(2, '0')}`
                  : ' 0:00'}
              </Text>
              <Text as="p">{t('sessionModal.sessionRefresh')}</Text>
            </Box>
          }
          modalFooter={
            <Box className="flex flex-wrap gap-3">
              <TextButton
                aria-label="logout"
                id="session-warning-logout-button"
                onClick={() => {
                  handleClose()
                  msalInstance.logout()
                }}
                variant={isLightMode ? 'blackAlpha' : 'whiteAlpha'}
              >
                {t('sessionModal.logout')}
              </TextButton>
              <TextButton
                aria-label="stay-signed-in"
                id="session-warning-stay-button"
                onClick={() => {
                  activate()
                  handleClose()
                }}
                variant={isLightMode ? 'kpmgCobaltBlue' : 'kpmgWhite'}
              >
                {t('sessionModal.stay')}
              </TextButton>
            </Box>
          }
          modalHeader={t('sessionModal.sessionPrompt')}
          onClose={() => {
            activate()
            handleClose()
          }}
        />
        {children}
      </AuthContext.Provider>
    </>
  )
}

export const useAuthContext = (): AuthContextType => useContext(AuthContext)
