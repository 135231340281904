import { Box, Text } from '@chakra-ui/layout'

import { useI18Context } from '../providers/i18Provider'
import { useSettingsContext } from '../providers/SettingsProvider'

import { TextButton } from './buttons/TextButton'

export const ClearFilesBody = () => {
  const { t } = useI18Context()
  return (
    <Box>
      <Text as="h2" className="mb-2 font-bold">
        {t('clearFilesModal.areYouSureClearFiles')}
      </Text>
      <Text as="p">{t('clearFilesModal.clearFilesExplanation')}</Text>
    </Box>
  )
}

type ClearFilesProps = {
  onClose: () => void
  primaryButtonOnClick: () => void
}

export const ClearFilesFooter = (props: ClearFilesProps) => {
  const { onClose, primaryButtonOnClick } = props
  const { t } = useI18Context()
  const { isLightMode } = useSettingsContext()

  const clearMessagesOnClick = () => {
    primaryButtonOnClick()
    onClose()
  }

  return (
    <Box className="flex flex-wrap gap-3">
      <TextButton
        aria-label="close"
        className="ml-auto text-sm md:text-base"
        id="close-button"
        onClick={onClose}
        variant={isLightMode ? 'blackAlpha' : 'whiteAlpha'}
      >
        {t('controls.cancel')}
      </TextButton>
      <TextButton
        aria-label="next"
        className="text-sm md:text-base"
        id="next-button"
        onClick={clearMessagesOnClick}
        variant={isLightMode ? 'kpmgCobaltBlue' : 'kpmgWhite'}
      >
        {t('clearFilesModal.clearAllFiles')}
      </TextButton>
    </Box>
  )
}