import { ErrorRes } from './api.types.js'
import { DocSec } from './api.cs.types.js'

// Sentences splits to sentences
export enum SplitType {
  KB = 'kb',
  SENTENCES = 'sentences',
}
// If using the generic strategy, extra HTML stylings will be removed (i.e. no <p>, <b>, <i> tags)
export enum IngestionStrategy {
  GENERIC = 'generic',
  OTHER = 'other',
}

export type BotInput = {
  splitType: keyof typeof SplitType
  strategy: keyof typeof IngestionStrategy
  splitSize?: string
}

export type FPType = {
  name: string
  SUPPORTED_FILETYPES: string[]
}
export const WordFPType: FPType = {
  name: 'word',
  SUPPORTED_FILETYPES: ['.docx'],
}
export const PDFFPType: FPType = {
  name: 'pdf',
  SUPPORTED_FILETYPES: ['.pdf'],
}
export const ExcelFPType: FPType = {
  name: 'excel',
  SUPPORTED_FILETYPES: ['.xlsx'],
}
export const CSVFPType: FPType = {
  name: 'csv',
  SUPPORTED_FILETYPES: ['.csv'],
}
export const JSONFPType: FPType = {
  name: 'json',
  SUPPORTED_FILETYPES: ['.json'],
}

export const ImageFPType: FPType = {
  name: 'img',
  SUPPORTED_FILETYPES: ['.jpg', '.jpeg', '.png', '.gif'],
}
export const TextFPType: FPType = {
  name: 'text',
  SUPPORTED_FILETYPES: [
    '.txt',
    '.py',
    '.xml',
    '.cpp',
    '.c',
    '.js',
    '.java',
    '.ts',
    '.css',
    '.tex',
    '.rb',
    '.php',
    '.md',
  ],
}

export const PPTFPType: FPType = {
  name: 'ppt',
  SUPPORTED_FILETYPES: ['.pptx'],
}
export const HTMLFPType: FPType = {
  name: 'html',
  SUPPORTED_FILETYPES: ['.html'],
}
export interface FPRequest {
  mspId: string
  traceId?: string
  fileUrl?: string //URL to fetch file content from
  data?: ReturnType<Buffer['toJSON']> //Buffer's data ot use. Create as Buffer.toString('binary')
  processor: FPType
  doOCR: boolean
}

export interface TableFPRequest extends FPRequest {
  keepStructure: boolean
  smallTableRowLimit?: number
}
export interface HTMLFPRequest extends FPRequest {
  botRequest: BotInput
}
export type FPResponse = DocSec[] | ErrorRes
