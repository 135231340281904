import { useCallback, useMemo } from 'react'
import { BiX } from 'react-icons/bi'
import { FormLabel } from '@chakra-ui/form-control'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/input'
import { Box, Stack, StackDivider } from '@chakra-ui/layout'
import { VisuallyHidden } from '@chakra-ui/visually-hidden'
import { DualLanguageOptionalValues } from '@kleo/types'

import { useI18Context } from 'providers/i18Provider'

type ProvidedValues = {
  providedInputValues: DualLanguageOptionalValues[]
  setProvidedInputValues: (values: DualLanguageOptionalValues[]) => void
}

type DynamicInputsProps = { maxInputs: number; maxCharacters: number; isDisabled?: boolean } & ProvidedValues

export const useDynamicInputs = ({
  isDisabled = false,
  maxInputs,
  maxCharacters,
  providedInputValues,
  setProvidedInputValues,
}: DynamicInputsProps) => {
  const { t } = useI18Context()

  // Calculate total character count for all inputs using providedInputValues
  const currentTotalCharacters = useMemo(
    () =>
      providedInputValues.reduce(
        (total: { en: number; fr: number }, value) => ({
          en: total.en + (value.en?.length ?? 0),
          fr: total.fr + (value.fr?.length ?? 0),
        }),
        { en: 0, fr: 0 } // Initial value for the accumulator
      ),
    [providedInputValues]
  )

  const handleInputChange = useCallback(
    (index: number, value: string, lang: 'en' | 'fr') => {
      const newTotalCharacters =
        currentTotalCharacters[lang] - (providedInputValues[index]?.[lang]?.length ?? 0) + value.length

      if (newTotalCharacters > maxCharacters) {
        return
      }

      const updatedValues = providedInputValues.map((input, idx) =>
        idx === index ? { ...input, [lang]: value } : input
      )

      setProvidedInputValues(updatedValues)

      // Add a new input if necessary and allowed by maxInputs limit
      if (value && index === providedInputValues.length - 1 && providedInputValues.length < maxInputs) {
        setProvidedInputValues([...updatedValues, { en: null, fr: null }])
      }
    },
    [providedInputValues, currentTotalCharacters, maxCharacters, maxInputs, setProvidedInputValues]
  )

  const handleRemoveInput = useCallback(
    (index: number) => {
      const updatedValues = providedInputValues.filter((_, idx) => idx !== index)
      setProvidedInputValues(updatedValues)
    },
    [providedInputValues, setProvidedInputValues]
  )

  const inputs = useMemo(
    () =>
      providedInputValues.map((inputValue, index) => (
        <Box
          key={`dynamic-${index}`}
          className="flex items-center justify-start mb-3 border rounded-md border-kpmgGray3 h-[51px]"
        >
          <Stack className="flex-1" spacing={0} divider={<StackDivider />}>
            <InputGroup size="xs">
              <VisuallyHidden>
                <FormLabel
                  htmlFor={`input-en-${index}`}
                >{`${t('kBots.createEdit.languageEN')} ${index + 1}`}</FormLabel>
              </VisuallyHidden>
              <InputLeftAddon className="flex items-center justify-center uppercase rounded-bl-none rounded-tl-md min-w-11">
                en
              </InputLeftAddon>
              <Input
                id={`input-en-${index}`}
                name={`input-en-${index}`}
                value={inputValue.en ?? ''}
                onChange={(e) => handleInputChange(index, e.target.value, 'en')}
                maxLength={300}
                variant="unstyled"
                className="pl-3"
                placeholder={`${t('kBots.createEdit.typeSomething')} (${t('kBots.createEdit.languageEN')})`}
                isDisabled={isDisabled}
              />
            </InputGroup>
            <InputGroup size="xs">
              <VisuallyHidden>
                <FormLabel
                  htmlFor={`input-fr-${index}`}
                >{`${t('kBots.createEdit.languageFR')} ${index + 1}`}</FormLabel>
              </VisuallyHidden>
              <InputLeftAddon className="flex items-center justify-center uppercase rounded-tl-none rounded-bl-md min-w-11">
                fr
              </InputLeftAddon>
              <Input
                id={`input-fr-${index}`}
                name={`input-fr-${index}`}
                value={inputValue.fr ?? ''}
                onChange={(e) => handleInputChange(index, e.target.value, 'fr')}
                maxLength={300}
                variant="unstyled"
                className="pl-3"
                placeholder={`${t('kBots.createEdit.typeSomething')} (${t('kBots.createEdit.languageFR')})`}
                isDisabled={isDisabled}
              />
            </InputGroup>
          </Stack>
          {providedInputValues.length > 1 && (
            <BiX className="w-6 h-6 cursor-pointer" onClick={() => handleRemoveInput(index)} />
          )}
        </Box>
      )),
    [handleInputChange, handleRemoveInput, providedInputValues, isDisabled, t]
  )

  return {
    inputs,
    inputValues: providedInputValues,
  }
}
