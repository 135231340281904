import { IconType } from 'react-icons'
import { AccordionButton, AccordionButtonProps, AccordionIcon } from '@chakra-ui/accordion'
import { Box, Text } from '@chakra-ui/layout'

import { useSettingsContext } from '../../providers/SettingsProvider'

type KleoAccordionButtonProps = {
  /** The text to show in the button */
  label: string
  /** Optional props to pass through to the chakra AccordionButton component. */
  /** The className prop will be ADDED to the className when we pass it through */
  accordionButtonProps?: AccordionButtonProps
  /** Whether or not we should show the focus ring on the button */
  focusable?: boolean
} & (
  | {
      /** Don't pass in either of Icon or Image for the default variant. */
      Icon?: never
      Image?: never
      iconClass?: never
      disableFullScreenMode?: never
    }
  | {
      /** The name of the Icon (e.g. BiIcon) to use. If defined, Image must be undefined. */
      Icon: IconType
      iconClass?: string
      Image?: never
      disableFullScreenMode?: boolean
    }
  | {
      Icon?: never
      iconClass?: never
      /** The element to use in place of the icon (should be an <Image> element). If defined, Icon must be undefined.
       * Image should already be styled when it is passed in.
       */
      Image: JSX.Element
      disableFullScreenMode?: boolean
    }
)

/**
 * A abstracted component for the accordion button to provide different variants and default styling. Has 3 variants, Icon, Image and default.
 */

export const KleoAccordionButton = (props: KleoAccordionButtonProps) => {
  const {
    accordionButtonProps,
    focusable = false,
    Icon,
    iconClass,
    Image,
    label,
    disableFullScreenMode = false,
  } = props

  const { isChatFullScreen, isLightMode } = useSettingsContext()

  return (
    <AccordionButton
      {...accordionButtonProps}
      className={`flex justify-between ${accordionButtonProps?.className} ${
        focusable && (isLightMode ? 'focus:ring-light focus:shadow-none' : 'focus:ring-dark focus:shadow-none')
      }`}
      tabIndex={0}
    >
      <Box className="flex items-center">
        {/* For width definitions below, we need to match what the K and Feedback buttons have: w-[34px] is w-8 + 2px worth of border. w-[50px] is w-12 + 2px worth of border */}
        {(Icon || Image) && (
          <Box
            className={`flex justify-center ${!disableFullScreenMode && !iconClass && isChatFullScreen && 'w-[34px] md:w-[50px] mr-2'}`}
          >
            {Icon && (
              <Icon
                className={
                  iconClass
                    ? iconClass
                    : `w-4 h-4 md:h-5 md:w-5 ${!disableFullScreenMode && !isChatFullScreen && 'mr-3 lg:mr-4'}`
                }
              />
            )}
            {Image && Image}
          </Box>
        )}
        <Text className="text-xs md:text-sm">{label}</Text>
      </Box>
      <AccordionIcon />
    </AccordionButton>
  )
}
