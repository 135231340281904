import mime from 'mime'

export const getFileExt = (fileType: string) => {
  return mime.getExtension(fileType)
}

export const getExtensionTypes = (supportedMimeTypes: string[]) => {
  const uniqueExtensions: Set<string> = new Set()
  supportedMimeTypes.forEach((fileType) => {
    const ext = getFileExt(fileType)

    if (ext && !uniqueExtensions.has(ext)) {
      if (ext === 'jpeg') {
        // for visualization, we need to show jpg as an option
        uniqueExtensions.add('jpg')
      }
      uniqueExtensions.add(ext)
    }
  })

  return [...Array.from(uniqueExtensions)]
}

export const isBinaryFile = (buffer: ArrayBuffer) => {
  const chunk = new Uint8Array(buffer.slice(0, 256))
  for (let i = 0; i < chunk.length; i++) {
    if (chunk[i] === 0) {
      return true
    }
  }

  return false
}
