import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'

type ScrollContextType = {
  resetStateExpanded: () => void
  stateClassNameString: string | undefined
  stateExpanded: ScrollState
  stateScroll: ScrollState
  handleScrollStateCallback: (scrollState: ScrollState) => void
  updateStateClassNameString: (name: string | undefined) => void
  updateStateExpanded: (
    conversationID: string,
    messageId: string,
    messageState: boolean,
    activeScrollToState: string | null
  ) => void
  updateStateScroll: (scrollState: ScrollState) => void
  setShouldScrollToGeneratedReport: Dispatch<SetStateAction<boolean>>
  shouldScrollToGeneratedReport: boolean
}

export const ScrollContext = createContext<ScrollContextType>({} as ScrollContextType)

type ScrollProviderProps = {
  children?: React.ReactNode
}

type ScrollState = {
  activeStates: Record<string, Record<string, boolean>>
  activeScrollTo: string | null
}

export const ScrollProvider = ({ children }: ScrollProviderProps) => {
  const [stateExpanded, setStateExpanded] = useState<ScrollState>({ activeStates: {}, activeScrollTo: null })
  const [stateScroll, setStateScroll] = useState<ScrollState>({ activeStates: {}, activeScrollTo: null })
  const [stateClassNameString, setStateClassNameString] = useState<string | undefined>()
  const [shouldScrollToGeneratedReport, setShouldScrollToGeneratedReport] = useState<boolean>(false)

  // type guard for identifier of scrollable item
  const handleScrollStateCallback = (scrollBoolean: ScrollState): void => {
    updateStateScroll(scrollBoolean)
    if (scrollBoolean['activeScrollTo']) {
      updateStateClassNameString(scrollBoolean['activeScrollTo'] as string)
    }
  }

  const updateStateExpanded = (
    conversationID: string,
    messageId: string,
    messageState: boolean,
    activeScrollToState: string | null
  ): void => {
    setStateExpanded((prevState) => {
      if (conversationID && messageId) {
        return {
          ...prevState,
          activeStates: {
            ...prevState.activeStates,
            [conversationID]: {
              [`${messageId}`]: messageState,
            },
          },
          activeScrollTo: activeScrollToState,
        }
      } else {
        return prevState
      }
    })
  }

  const resetStateExpanded = (): void => {
    setStateExpanded((prevState) => {
      return {
        ...prevState,
        activeStates: {},
        activeScrollTo: null,
      }
    })
  }

  const updateStateScroll = (scrollState: ScrollState) => {
    setStateScroll(scrollState)
  }

  const updateStateClassNameString = (name: string | undefined) => {
    setStateClassNameString(name)
  }

  return (
    <>
      <ScrollContext.Provider
        value={{
          handleScrollStateCallback,
          resetStateExpanded,
          setShouldScrollToGeneratedReport,
          shouldScrollToGeneratedReport,
          stateClassNameString,
          stateExpanded,
          stateScroll,
          updateStateClassNameString,
          updateStateExpanded,
          updateStateScroll,
        }}
      >
        {children}
      </ScrollContext.Provider>
    </>
  )
}

export const useScrollContext = (): ScrollContextType => useContext(ScrollContext)
