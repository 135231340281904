import { useCallback, useMemo, useState } from 'react'
import { BiInfoCircle } from 'react-icons/bi'
import { Box, Heading, Text } from '@chakra-ui/layout'
import { Tooltip } from '@chakra-ui/tooltip'
import { Fade } from '@chakra-ui/transition'
import { DualLanguageOptionalValues, PromptLibraryPrompts } from '@kleo/types'

import { useDynamicInputs } from 'hooks/useDynamicInputs'

import { useI18Context } from 'providers/i18Provider'
import { useSettingsContext } from 'providers/SettingsProvider'

import { GetKBotFormValue, KBotFormState, KBotFormValues, SetKBotFormValue, ValidationErrors } from 'types/types'

export const KBotStarterPrompts = ({
  formErrors,
  getKBotFormValue,
  icon,
  maxInput,
  setKBotFormValue,
  shouldInputsBeDisabled,
  state,
}: {
  formErrors: ValidationErrors<KBotFormValues>
  getKBotFormValue: GetKBotFormValue
  icon: string | undefined
  maxInput: number
  setKBotFormValue: SetKBotFormValue
  shouldInputsBeDisabled: boolean
  state: KBotFormState
}) => {
  const { t } = useI18Context()
  const { isLightMode } = useSettingsContext()

  const startPromptsValue = useMemo(() => getKBotFormValue('starterPrompts', state), [getKBotFormValue, state])

  return (
    <>
      <Box className="flex items-center justify-start mb-4">
        <Heading as="h2" id="kbot-start-prompt-heading" size="sm" className="mr-2">
          {t('kBots.createEdit.starterPrompts')}
        </Heading>
        <Tooltip
          className={`text-xs ${isLightMode ? 'text-white' : 'text-black'}`}
          label={
            <>
              <Text>{t('kBots.createEdit.startPromptsExplanation')}</Text>
              <Text>{t('kBots.createEdit.startPromptsExplanation2')}</Text>
            </>
          }
          placement="right"
          aria-describedby="kbot-start-prompt-heading"
        >
          <Fade in>
            <BiInfoCircle className="self-end w-4 h-4 text-gray-600" />
          </Fade>
        </Tooltip>
      </Box>
      {formErrors?.starterPrompts && (
        <Text as="span" className={`block mb-2 text-sm ${isLightMode ? 'text-red-600' : 'text-red-400'}`}>
          {t(formErrors.starterPrompts)}
        </Text>
      )}
      {Object.keys(formErrors).map(
        (key) =>
          key.includes('starterPrompts') && (
            <Text
              key={`starterPromptError-${key}`}
              as="span"
              className={`block mb-2 text-sm ${isLightMode ? 'text-red-600' : 'text-red-400'}`}
            >
              {formErrors[key as keyof KBotFormValues]}
            </Text>
          )
      )}
      <KBotPromptInputs
        formErrors={formErrors}
        icon={icon}
        isDisabled={shouldInputsBeDisabled}
        maxInput={maxInput}
        setKBotFormValue={setKBotFormValue}
        starterPrompt={startPromptsValue}
        state={state}
      />
    </>
  )
}

/**
 *
 * @param param0
 * @returns Dynamically generated inputs for the prompts, in english and french
 */
const KBotPromptInputs = ({
  formErrors,
  icon,
  isDisabled,
  maxInput,
  setKBotFormValue,
  starterPrompt,
  state,
}: {
  formErrors: ValidationErrors<KBotFormValues>
  icon: string | undefined
  isDisabled: boolean
  maxInput: number
  setKBotFormValue: SetKBotFormValue
  starterPrompt: PromptLibraryPrompts
  state: KBotFormState
}) => {
  const { t } = useI18Context()
  const { isLightMode } = useSettingsContext()
  const [lengthError, setLengthError] = useState<string | null>(null)

  const setPromptsValues = useCallback(
    async (promptValues: DualLanguageOptionalValues[]) => {
      // sort the keys as numbers
      const orderedValues = Object.keys(promptValues)
        .map(Number)
        .sort()
        .map((key) => promptValues[key])

      const totalLength = orderedValues.reduce((acc, desc) => {
        const enLength = desc.en ? desc.en.length : 0
        const frLength = desc.fr ? desc.fr.length : 0
        return acc + enLength + frLength
      }, 0)

      if (totalLength >= maxInput) {
        setLengthError(t('kBots.error.descriptionMaxLengthExceeded'))
      } else {
        setLengthError(null)
        setKBotFormValue('starterPrompts', (prevSP) => ({ ...prevSP, icon, prompts: orderedValues }), state)
      }
    },
    [icon, maxInput, setKBotFormValue, state, t]
  )

  const mappedDefaultInputs = useMemo(() => {
    if (!starterPrompt?.prompts || starterPrompt.prompts.length === 0) {
      // If starterPrompt.prompts is empty or undefined, return a default input
      return [{ en: null, fr: null }]
    }

    return starterPrompt.prompts.map((prompt) => ({
      en: prompt.en ?? null,
      fr: prompt.fr ?? null,
    }))
  }, [starterPrompt])

  const { inputs: promptInputs } = useDynamicInputs({
    isDisabled,
    maxCharacters: maxInput,
    maxInputs: 10,
    providedInputValues: mappedDefaultInputs,
    setProvidedInputValues: setPromptsValues,
  })

  return (
    <Box>
      {formErrors?.starterPrompts && (
        <Text as="span" className={`block mb-2 text-sm ${isLightMode ? 'text-red-600' : 'text-red-400'}`}>
          {formErrors.starterPrompts}
        </Text>
      )}
      {lengthError && (
        <Text as="span" className={`block mb-2 text-sm ${isLightMode ? 'text-red-600' : 'text-red-400'}`}>
          {lengthError}
        </Text>
      )}
      {promptInputs}
    </Box>
  )
}
