import { ChangeEvent, useCallback } from 'react'
import { BiInfoCircle } from 'react-icons/bi'
import { FormLabel } from '@chakra-ui/form-control'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/input'
import { Box, Heading, Stack, StackDivider, Text } from '@chakra-ui/layout'
import { Tooltip } from '@chakra-ui/tooltip'
import { Fade } from '@chakra-ui/transition'
import { VisuallyHidden } from '@chakra-ui/visually-hidden'
import { DualLanguageOptionalValues } from '@kleo/types'

import { useIconsMenu } from 'hooks/useIconsMenu'

import { useI18Context } from 'providers/i18Provider'
import { useSettingsContext } from 'providers/SettingsProvider'

import { KBotFormState, KBotFormValues, SetKBotFormValue, ValidationErrors } from 'types/types'

export const KBotName = ({
  formErrors,
  icon,
  maxLength,
  nameValues,
  setKBotFormValue,
  shouldInputsBeDisabled,
  state,
}: {
  formErrors: ValidationErrors<KBotFormValues>
  icon: string | undefined
  maxLength: number
  nameValues: DualLanguageOptionalValues
  setKBotFormValue: SetKBotFormValue
  shouldInputsBeDisabled: boolean
  state: KBotFormState
}) => {
  const { t, languageAbbreviation } = useI18Context()
  const { isLightMode } = useSettingsContext()

  const { IconsMenu } = useIconsMenu({
    isDisabled: shouldInputsBeDisabled,
    iconValue: icon,
    onIconChange: (icon) => setKBotFormValue('icon', icon, state),
    mode: state,
  })

  const handleInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>, lang: 'en' | 'fr') => {
      setKBotFormValue('name', { ...nameValues, [lang]: e.target.value }, state)
    },
    [nameValues, setKBotFormValue, state]
  )

  return (
    <>
      <Box className="flex items-center justify-start mb-4">
        <Heading as="h2" id="kbot-name-heading" className="mr-2" size="sm">
          {t('kBots.createEdit.name')}{' '}
          <Text as="span" className="text-red-600">
            *
          </Text>
        </Heading>
        <Tooltip
          className={`text-xs ${isLightMode ? 'text-white' : 'text-black'}`}
          label={
            <>
              <Text>{t('kBots.createEdit.nameExplanation', { name: nameValues[languageAbbreviation] })}</Text>
            </>
          }
          placement="right"
          aria-describedby="kbot-name-heading"
        >
          <Fade in>
            <BiInfoCircle className="self-end w-4 h-4 text-gray-600" />
          </Fade>
        </Tooltip>
      </Box>
      {formErrors.name && (
        <Text as="span" className={`block mb-2 text-sm ${isLightMode ? 'text-red-600' : 'text-red-400'}`}>
          {t(formErrors.name)}
        </Text>
      )}
      <Box className="flex items-center justify-start gap-4">
        <Box>{IconsMenu}</Box>
        <Box className="border rounded-md border-kpmgGray3 h-[51px] flex-1">
          <Stack spacing={0} divider={<StackDivider />}>
            <InputGroup size="xs">
              <VisuallyHidden>
                <FormLabel htmlFor="kbot-name-en">{`${t('kBots.createEdit.languageEN')} ${t('kBots.createEdit.name')}`}</FormLabel>
              </VisuallyHidden>
              <InputLeftAddon className="flex items-center justify-center uppercase rounded-bl-none rounded-tl-md min-w-11">
                en
              </InputLeftAddon>
              <Input
                id="kbot-name-en"
                name="kBotName"
                value={nameValues.en ?? ''}
                onChange={(e) => handleInput(e, 'en')}
                maxLength={maxLength}
                variant="unstyled"
                className="pl-3"
                placeholder={`${t('kBots.createEdit.typeSomething')} (${t('kBots.createEdit.languageEN')})`}
                isDisabled={shouldInputsBeDisabled}
              />
            </InputGroup>
            <InputGroup size="xs">
              <VisuallyHidden>
                <FormLabel htmlFor="kbot-name-fr">{`${t('kBots.createEdit.languageFR')} ${t('kBots.createEdit.name')}`}</FormLabel>
              </VisuallyHidden>
              <InputLeftAddon className="flex items-center justify-center uppercase rounded-tl-none rounded-bl-md min-w-11">
                fr
              </InputLeftAddon>
              <Input
                id="kbot-name-fr"
                name="kBotName"
                value={nameValues.fr ?? ''}
                onChange={(e) => handleInput(e, 'fr')}
                maxLength={maxLength}
                variant="unstyled"
                className="pl-3"
                placeholder={`${t('kBots.createEdit.typeSomething')} (${t('kBots.createEdit.languageFR')})`}
                isDisabled={shouldInputsBeDisabled}
              />
            </InputGroup>
          </Stack>
        </Box>
      </Box>
    </>
  )
}
