import { forwardRef, ReactElement } from 'react'
import { IconType } from 'react-icons'
import { Button, ButtonProps } from '@chakra-ui/button'

import { useSettingsContext } from 'providers/SettingsProvider'

import { CustomButtonProps } from 'types/types'

type LeftOrRightIcon =
  | {
      leftIcon: ReactElement<IconType>
      rightIcon?: never
    }
  | {
      leftIcon?: never
      rightIcon: ReactElement<IconType>
    }

export type TextIconButtonProps = Omit<ButtonProps, 'leftIcon' | 'rightIcon'> & LeftOrRightIcon & CustomButtonProps

export const TextIconButton = forwardRef<HTMLButtonElement, TextIconButtonProps>((props, ref) => {
  const { className: classNameToAdd, padding = 'py-2', rounded = 'lg', variant, whiteSpace = 'normal', ...rest } = props
  const { isLightMode } = useSettingsContext()

  const isLightButton =
    variant === 'kpmgWhite' ||
    variant === 'blackAlpha' ||
    (isLightMode && (variant === 'outline' || variant === 'ghost'))

  return (
    <Button
      {...rest}
      className={`h-auto shadow-none ${isLightButton ? 'focus-visible:ring-light' : 'focus-visible:ring-dark'} ${padding} ${classNameToAdd}`}
      ref={ref}
      rounded={rounded}
      tabIndex={0}
      variant={variant}
      whiteSpace={whiteSpace}
    >
      {props.children}
    </Button>
  )
})