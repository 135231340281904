import { ErrorRes, OpenAITemperature, TRequest, TResponse } from './api.types.js'

export enum SourceType {
  kleo = 'kleo',
  user = 'user',
}

export type DualLanguageOptionalValues = {
  en: string | null
  fr: string | null
}

export type DualLanguageRequiredValues = {
  en: string
  fr: string
}

export type TemplateType = DualLanguageRequiredValues & { icon?: string }

export type StarterPrompt = PromptLibraryPrompts | PromptLibraryParentPrompts

export type PromptLibraryPrompts = {
  icon?: string
  category: DualLanguageRequiredValues
  prompts: DualLanguageOptionalValues[]
}
export type PromptLibraryParentPrompts = {
  icon?: string
  category: DualLanguageRequiredValues
  subPrompts: PromptLibraryPrompts[]
}

export type KBotConfig = {
  author: string
  date: number
  description: DualLanguageOptionalValues[]
  fileContent: string
  kbotTemperature: OpenAITemperature
  name: string
  source: SourceType
  starterPrompts: StarterPrompt[]
  template: TemplateType
  templateId: string
  userInstructions: string
}

export type KBotBaseConfig = Pick<KBotConfig, 'templateId' | 'date' | 'template' | 'description' | 'source' | 'name'>

export interface ListKBotsResponse extends TResponse {
  botConfigs: KBotBaseConfig[]
}

/**
 * possible error codes:
 * DescriptionOverSize,
 * TemplateOverSize,
 * UserInstructionOverSize,
 * StarterPromptOverSize,
 * StarterPromptTooBig: indicates the total size of the starterPrompts is too large and over 2mb size limit of the DB entry.
 * FileContentTooBig: indicates the fileContent is more than 2mb (larger than DB entry size)
 * SourceTypeWrong: indicates that user tries to update the system bot
 * UserKBotExists: the user tried to create a KBot that has the same name as another KBot
 */
export interface SaveKBotRequest extends TRequest {
  botConfig: Omit<KBotConfig, 'author' | 'date' | 'templateId' | 'name'> & { templateId?: string; name?: string }
}

export type SaveKBotResponse = TResponse & ({ templateId: string; template: DualLanguageRequiredValues } | ErrorRes)

export type DownloadKBotRequest = TRequest & { templateId: string }

export type DeleteKBotRequest = TRequest & { templateId: string }

export type DeleteKBotResponse = TResponse & (void | ErrorRes)

export type GetKBotRequest = TRequest & { name: string; source: SourceType }

export type GetKBotResponse = TResponse & { botConfig: KBotConfig }
