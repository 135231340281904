import { createContext, useContext } from 'react'

import type { BotFunctions, ChatMessage, ConversationSettings, FEUIConfig, StreamingStep } from 'types/types'

type BotSpecificContextType = {
  botName: string
  currentConversationSettings: ConversationSettings | undefined
  functions: BotFunctions
  isFetchingChart: boolean
  isKBot?: boolean
  isStreaming: boolean
  messages: ChatMessage[]
  streamingMessage: ChatMessage | null
  streamingStep: StreamingStep | undefined
  streamingSummary: string | null
  urlLinks: FEUIConfig['urlLinks']
}
export const BotSpecificContext = createContext<BotSpecificContextType>({} as BotSpecificContextType)
type BotSpecificProviderProps = {
  botName: string
  children?: React.ReactNode
  currentConversationSettings: ConversationSettings | undefined
  functions: BotFunctions
  isFetchingChart: boolean
  isKBot?: boolean
  isStreaming: boolean
  messages: ChatMessage[]
  streamingMessage: ChatMessage | null
  streamingStep: StreamingStep | undefined
  streamingSummary: string | null
  urlLinks: FEUIConfig['urlLinks']
}

export const BotSpecificProvider = ({
  botName,
  children,
  currentConversationSettings,
  functions,
  isFetchingChart,
  isKBot,
  isStreaming,
  messages,
  streamingMessage,
  streamingStep,
  streamingSummary,
  urlLinks,
}: BotSpecificProviderProps) => {
  return (
    <BotSpecificContext.Provider
      value={{
        botName,
        currentConversationSettings,
        functions,
        isFetchingChart,
        isKBot,
        isStreaming,
        messages,
        streamingMessage,
        streamingStep,
        streamingSummary,
        urlLinks,
      }}
    >
      {children}
    </BotSpecificContext.Provider>
  )
}

export const useBotSpecificContext = (): BotSpecificContextType => useContext(BotSpecificContext)
