import { BiX } from 'react-icons/bi'
import { Alert, AlertDescription, AlertTitle } from '@chakra-ui/alert'
import { Box, Text } from '@chakra-ui/layout'
import { Collapse } from '@chakra-ui/transition'
import cx from 'classnames'

import { useSettingsContext } from 'providers/SettingsProvider'

import { IconButton } from './buttons/IconButton'

export type BannerProps = {
  className?: string
  description: JSX.Element
  isVisible: boolean
  onClick: () => void
  status?: 'success' | 'error' | 'warning' | 'info'
  title?: string
  variant?: 'subtle' | 'solid' | 'left-accent' | 'top-accent'
  noCloseBtn?: boolean
}

const Banner = ({
  className,
  description,
  isVisible,
  onClick,
  status = 'warning',
  title,
  variant = 'subtle',
  noCloseBtn = false,
}: BannerProps) => {
  const { isDarkMode } = useSettingsContext()

  const getDarkmodeClassNames = () => {
    return cx({
      'bg-orange-100': isDarkMode && status === 'warning',
      'bg-red-100': isDarkMode && status === 'error',
      'bg-green-100': isDarkMode && status === 'success',
      'bg-blue-100': isDarkMode && status === 'info',
    })
  }

  return (
    <Collapse in={isVisible} animateOpacity>
      <Box className={className}>
        <Alert className={`text-gray-800 p-3 ${getDarkmodeClassNames()}`} status={status} variant={variant}>
          <Box className="w-full text-xs">
            {title && (
              <AlertTitle className="flex justify-between mb-1 mr-0">
                <Text>{title}</Text>
                {!noCloseBtn && (
                  <IconButton
                    size="xs"
                    onClick={onClick}
                    aria-label="close-warning-banner"
                    variant="blackAlpha"
                    iconName={BiX}
                    iconClassName="text-lg md:text-xl text-gray-800"
                  />
                )}
              </AlertTitle>
            )}
            <AlertDescription className="leading-4">{description}</AlertDescription>
          </Box>
        </Alert>
      </Box>
    </Collapse>
  )
}

export { Banner }
