import { Box, Text } from '@chakra-ui/layout'

import { aboutKPMGLinks, helpAndSupportLinks, usefulLinks } from '../constants/footerLinks'
import { useI18Context } from '../providers/i18Provider'
import { useSettingsContext } from '../providers/SettingsProvider'

import { FooterLink } from './Link'

export const Footer = () => {
  const { isLightMode } = useSettingsContext()
  const { t, language, languageAbbreviation } = useI18Context()

  const helpAndSupportLinksTranslated = helpAndSupportLinks[language]
  const usefulLinksTranslated = usefulLinks[language]
  const aboutKPMGLinksTranslated = aboutKPMGLinks[language]

  return (
    <Box className="py-10">
      <Box className="width-layout">
        <Box className="flex flex-col items-start mx-4 md:mx-0 md:mb-8 md:grid md:grid-cols-4 md:gap-4">
          <img
            className="h-8 mb-4 md:mb-0 hover:cursor-pointer"
            src={isLightMode ? '../images/kpmg.svg' : '../images/kpmgWhite.svg'}
            alt="KPMG"
          />
          <Box className="mb-4 md:mb-0">
            <Box tabIndex={0} className="mb-2">
              {t('footer.headers.helpAndSupport')}
            </Box>
            <Box className="flex flex-col">
              {Object.keys(helpAndSupportLinksTranslated).map((property: string, index: number) => (
                <FooterLink
                  key={`${property}_${index}`}
                  name={t(`footer.links.${property}`)}
                  url={helpAndSupportLinksTranslated[property]}
                />
              ))}
            </Box>
          </Box>
          <Box className="mb-4 md:mb-0">
            <Box tabIndex={0} className="mb-2">
              {t('footer.headers.usefulLinks')}
            </Box>
            <Box className="flex flex-col">
              {Object.keys(usefulLinksTranslated).map((property: string, index: number) => (
                <FooterLink
                  key={`${property}_${index}`}
                  name={t(`footer.links.${property}`)}
                  url={usefulLinksTranslated[property]}
                />
              ))}
            </Box>
          </Box>
          <Box className="mb-4 md:mb-0">
            <Box tabIndex={0} className="mb-2">
              {t('footer.headers.aboutKPMG')}
            </Box>
            <Box className="flex flex-col">
              {Object.keys(aboutKPMGLinksTranslated).map((property: string, index: number) => (
                <FooterLink
                  key={`${property}_${index}`}
                  name={t(`footer.links.${property}`)}
                  url={aboutKPMGLinksTranslated[property]}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <hr className="mb-4" />
        {languageAbbreviation === 'en' ? (
          <Box className="mx-4 text-xs md:mx-0">{t('footer.legalVerbiage')}</Box>
        ) : (
          <Box className="mx-4 text-xs md:mx-0">
            <Text as="span">{t('footer.legalVerbiage1')}</Text>
            <Text as="span" className="text-2xs">
              {t('footer.legalVerbiage2')}
            </Text>
            <Text as="span">{t('footer.legalVerbiage3')}</Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}
