import { forwardRef } from 'react'
import { IconType } from 'react-icons'
import { IconButton as ChakraIconButton, IconButtonProps as ChakraIconButtonProps } from '@chakra-ui/button'

import { useSettingsContext } from 'providers/SettingsProvider'

import { CustomButtonProps } from 'types/types'

type IconButtonProps = {
  iconName: IconType
  iconClassName: string
} & Omit<ChakraIconButtonProps, 'icon'> &
  CustomButtonProps

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const { className: classNameToAdd, iconClassName, iconName: Icon, rounded = 'lg', variant, ...rest } = props
  const { isLightMode } = useSettingsContext()

  const isLightButton =
    variant === 'kpmgWhite' ||
    variant === 'blackAlpha' ||
    (isLightMode && (variant === 'outline' || variant === 'ghost'))

  return (
    <ChakraIconButton
      {...rest}
      className={`shadow-none ${isLightButton ? 'focus-visible:ring-light' : 'focus-visible:ring-dark'} ${classNameToAdd}`}
      icon={<Icon className={iconClassName} />}
      ref={ref}
      rounded={rounded}
      tabIndex={0}
      variant={variant}
    />
  )
})