import { useCallback, useMemo } from 'react'
import { BiInfoCircle } from 'react-icons/bi'
import { Card, CardBody } from '@chakra-ui/card'
import { Box, Divider, Heading, Text } from '@chakra-ui/layout'
import { Skeleton } from '@chakra-ui/skeleton'
import { Tooltip } from '@chakra-ui/tooltip'
import { Fade } from '@chakra-ui/transition'

import { TextButton } from 'components/buttons/TextButton'
import { KBotDescriptions } from 'components/kbots/formComponents/KBotDescriptions'
import { KBotInstructions } from 'components/kbots/formComponents/KBotInstructions'
import { KBotName } from 'components/kbots/formComponents/KBotName'
import { KBotStarterPrompts } from 'components/kbots/formComponents/KBotStarterPrompts'
import { BasicSelect } from 'components/Select'
import { UploadWithPreview } from 'components/UploadWithPreview'

import { useI18Context } from 'providers/i18Provider'
import { useKBotContext } from 'providers/KBotsProvider'
import { useSettingsContext } from 'providers/SettingsProvider'
import { useThemeContext } from 'providers/ThemeProvider'

import type { GroupedOptions, KBotFormState, SearchFilterItem } from 'types/types'

import { KBotTemperature } from './formComponents/KBotTemperature'

type CreateEditFormProps = {
  botName: string
  conversationId: string
  fileSubmit: (files: File[]) => void
  state: KBotFormState
  submissionErrors: Record<KBotFormState, string[]>
  submit: () => Promise<void>
}

export const CreateEditForm = ({
  botName,
  conversationId,
  fileSubmit,
  state,
  submit,
  submissionErrors,
}: CreateEditFormProps) => {
  const {
    createFormErrors,
    editFormErrors,
    fetchIndividualKBotDataError,
    generalConfig: {
      supportedFileExtensions,
      supportedMimeTypes,
      uploadTokenMaxSize,
      descriptionMaxLength,
      instructionsMaxLength,
      nameMaxLength,
    },
    getKBotFormValue,
    getSelectedBot,
    isFetchingIndividualKBotData,
    onJSONUploadOpen,
    setKBotFormValue,
    uploadJSONError,
  } = useKBotContext()

  const { t } = useI18Context()
  const { isLightMode } = useSettingsContext()
  const { isTablet } = useThemeContext()

  const handleOnSubmit = useCallback(
    (files: File[]) => {
      fileSubmit(files)
    },
    [fileSubmit]
  )

  const onDocContentsClear = useCallback(() => {
    setKBotFormValue('fileContent', undefined, state, true)
  }, [setKBotFormValue, state])

  const nameValues = useMemo(() => getKBotFormValue('name', state), [getKBotFormValue, state])
  const instructionsValue = useMemo(() => getKBotFormValue('instructions', state), [getKBotFormValue, state])
  const iconValue = useMemo(() => getKBotFormValue('icon', state), [getKBotFormValue, state])

  const enableCreateButton = useMemo(() => {
    if (!nameValues.en && !nameValues.fr) return false

    if (!instructionsValue) return false

    return true
  }, [instructionsValue, nameValues.en, nameValues.fr])

  const shouldInputsBeDisabled = useMemo(() => {
    if (state === 'create') {
      return false
    }

    if (!getSelectedBot(state)) {
      return true
    }

    return false
  }, [getSelectedBot, state])

  const submissionErrorsList = useMemo(() => {
    return submissionErrors[state]
  }, [state, submissionErrors])

  const formErrors = useMemo(() => {
    return state === 'edit' ? editFormErrors : createFormErrors
  }, [createFormErrors, editFormErrors, state])

  return (
    <Box className={`flex flex-col w-full h-full rounded-lg  ${isLightMode ? 'bg-white' : 'bg-kpmgDarkBlue'}`}>
      <Box className="relative p-3 pb-0 md:p-4 md:pb-0">
        <Text
          as="h1"
          className={`mb-2 text-2xl md:text-3xl lg:text-4xl font-opensanscondensed ${isLightMode ? 'text-kpmgBlue' : 'text-white'}`}
        >
          {state === 'edit' ? t('kBots.createEdit.edit') : t('kBots.createEdit.create')} {t('kBots.createEdit.aKBot')}
        </Text>
        <Text as="h2" className={`text-sm md:text-base ${isLightMode ? 'text-kpmgGray2' : 'text-kpmgGray4'}`}>
          {state === 'edit' ? t('kBots.editKBotsDescription') : t('kBots.createKBotsDescription')}
        </Text>
        <Box className="absolute top-3 right-3 md:top-4 md:right-4">
          <TextButton onClick={submit} isDisabled={!enableCreateButton} colorScheme="green" variant="solid">
            {state === 'edit' ? t('kBots.createEdit.save') : t('kBots.createEdit.create')}
          </TextButton>
        </Box>
        <Box className={`h-[1px] w-full mt-2 bg-opacity-75 ${isLightMode ? 'bg-kpmgGray5' : 'bg-kpmgGray3'}`} />
      </Box>

      <Box className="h-full p-3 overflow-y-auto md:p-4">
        {submissionErrorsList.length ? (
          <Box className="mb-3">
            {submissionErrorsList.map((subErr) => (
              <Text key={subErr} className={`text-sm ${isLightMode ? 'text-red-600' : 'text-red-400'}`}>
                {subErr}
              </Text>
            ))}
          </Box>
        ) : null}
        <Box className="grid grid-cols-2 gap-4">
          <Box className="col-span-2 lg:col-span-1">
            <KBotSelect state={state} />
          </Box>
          <Box className="col-span-2 lg:col-span-1">
            <Heading as="h2" size="sm" className="mb-0.5">
              {t('kBots.createEdit.uploadJSONHeader')}
            </Heading>
            <Text as="h3" className="mb-2 text-xs">
              {t('kBots.createEdit.uploadJSONSubheader')}
            </Text>
            <Box className="flex flex-wrap items-center justify-start">
              <TextButton
                className="mr-4"
                size={isTablet ? 'md' : 'sm'}
                isDisabled={shouldInputsBeDisabled}
                onClick={onJSONUploadOpen}
              >
                {t('kBots.createEdit.uploadJSON')}
              </TextButton>
              {uploadJSONError && (
                <Text as="span" className={`block text-sm ${isLightMode ? 'text-red-600' : 'text-red-400'}`}>
                  {uploadJSONError}
                </Text>
              )}
            </Box>
          </Box>
          <Divider className="col-span-2" />
          {isFetchingIndividualKBotData ? (
            [...Array(5)].map((_, index) => (
              <Skeleton key={`skeleton-${index}`} className="w-full my-2" height="16px" />
            ))
          ) : (
            <>
              <Box className="col-span-2 lg:col-span-1">
                {fetchIndividualKBotDataError && !!fetchIndividualKBotDataError.length && (
                  <Box className="flex w-full pb-2 text-red-400">
                    {`${t(`kBots.error.${fetchIndividualKBotDataError}`)} ${t('kBots.error.allowEditWithError')}`}
                  </Box>
                )}
                <KBotName
                  formErrors={formErrors}
                  icon={iconValue}
                  nameValues={nameValues}
                  setKBotFormValue={setKBotFormValue}
                  shouldInputsBeDisabled={shouldInputsBeDisabled}
                  state={state}
                  maxLength={nameMaxLength}
                />
              </Box>
              <Box className="col-span-2 lg:col-span-1">
                <KBotDescriptions
                  formErrors={formErrors}
                  setKBotFormValue={setKBotFormValue}
                  shouldInputsBeDisabled={shouldInputsBeDisabled}
                  state={state}
                  maxLength={descriptionMaxLength}
                />
              </Box>
              <Box className="col-span-2">
                <KBotInstructions
                  formErrors={formErrors}
                  instructionsValue={instructionsValue}
                  setKBotFormValue={setKBotFormValue}
                  shouldInputsBeDisabled={shouldInputsBeDisabled}
                  state={state}
                  maxLength={instructionsMaxLength}
                />
              </Box>
              <Divider className="col-span-2" />
              <Box className="col-span-2 lg:col-span-1">
                <KBotStarterPrompts
                  formErrors={formErrors}
                  getKBotFormValue={getKBotFormValue}
                  icon={iconValue}
                  // The maximum input for a start prompt can technically be the max tokens we can use
                  maxInput={uploadTokenMaxSize * 4}
                  setKBotFormValue={setKBotFormValue}
                  shouldInputsBeDisabled={shouldInputsBeDisabled}
                  state={state}
                />
              </Box>
              <Box className="col-span-2 lg:col-span-1">
                <KBotTemperature
                  getKBotFormValue={getKBotFormValue}
                  setKBotFormValue={setKBotFormValue}
                  shouldInputsBeDisabled={shouldInputsBeDisabled}
                  state={state}
                />
              </Box>
              <Divider className="col-span-2" />
              <Box className="col-span-2">
                <Box className="overflow-y-auto max-h-[700px]">
                  <Box className="flex items-center justify-start mb-4">
                    <Heading as="h2" id="kbot-knowledge-base-heading" className="mr-2" size="sm">
                      {t('kBots.createEdit.knowledgeBase')}
                    </Heading>
                    <Tooltip
                      className={`text-xs ${isLightMode ? 'text-white' : 'text-black'}`}
                      label={
                        <>
                          <Text>{t('kBots.createEdit.knowledgeBaseDescription')}</Text>
                        </>
                      }
                      placement="right"
                      aria-describedby="kbot-knowledge-base-heading"
                    >
                      <Fade in>
                        <BiInfoCircle className="self-end w-4 h-4 text-gray-600" />
                      </Fade>
                    </Tooltip>
                  </Box>
                  {formErrors.fileContent && (
                    <Text as="span" className={`block mb-2 text-sm ${isLightMode ? 'text-red-600' : 'text-red-400'}`}>
                      {t(formErrors.fileContent)}
                    </Text>
                  )}
                  <Card className="border border-kpmgGray3">
                    <CardBody>
                      <UploadWithPreview
                        allowMultipleFiles={true}
                        botName={botName}
                        currentConversationID={conversationId}
                        kBotId={conversationId}
                        isDisabled={shouldInputsBeDisabled}
                        isExpanded={true}
                        isFetchingChart={false}
                        isStreaming={false}
                        onClear={onDocContentsClear}
                        onSubmit={handleOnSubmit}
                        supportedFileExtensions={supportedFileExtensions || []}
                        supportedMimeTypes={supportedMimeTypes || []}
                        uploadTokenMaxSize={uploadTokenMaxSize ?? 0}
                      />
                    </CardBody>
                  </Card>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const KBotSelect = ({ state }: { state: KBotFormState }) => {
  const { languageAbbreviation, t } = useI18Context()
  const { handleBotSelect, userKBotOptions, systemKBotOptions, getSelectedBot } = useKBotContext()

  const selectedKBot = useMemo(() => {
    const selectedBotValue = getSelectedBot(state)
    // If the kbot is deleted and not present in the userKBotOptions or systemKBotOptions
    // then no default value from the dropdown
    const getAllKbotOptions = [...(userKBotOptions || []), ...(systemKBotOptions || [])]
    if (selectedBotValue && getAllKbotOptions?.find((opt) => opt.value === selectedBotValue?.templateId)) {
      return { value: selectedBotValue.templateId, label: selectedBotValue.template[languageAbbreviation] }
    }
    return null
  }, [getSelectedBot, languageAbbreviation, systemKBotOptions, userKBotOptions, state])

  const groupedOptions: GroupedOptions<SearchFilterItem>[] = useMemo(() => {
    return [
      {
        label: t('kBots.systemKBots'),
        options: systemKBotOptions || [],
      },
      {
        label: t('kBots.myKBots'),
        options: userKBotOptions || [],
      },
    ]
  }, [systemKBotOptions, t, userKBotOptions])

  return (
    <Box>
      <Heading as="h2" size="sm" className="mb-0.5">
        {t('kBots.createEdit.kBotTemplates')} {state === 'edit' ? <span className="text-red-600">*</span> : null}
      </Heading>
      <Text className="mb-2 text-xs">
        {state === 'edit'
          ? t('kBots.createEdit.kBotTemplateEditDescription')
          : t('kBots.createEdit.kBotTemplateCreateDescription')}
      </Text>
      <BasicSelect
        className="text-sm"
        onChange={(e) => handleBotSelect(e, state)}
        placeholder={t('kBots.createEdit.kBotTemplatePlaceholder')}
        value={selectedKBot}
        options={state === 'edit' ? userKBotOptions || [] : groupedOptions}
      />
    </Box>
  )
}
