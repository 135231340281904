import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'
import { GetProfileDataResponse } from '@kleo/types'

import { useFetcher } from 'hooks/useFetcher'

import { ChildrenProps } from 'types/types'

type UserProfileContextType = {
  isFetchingUsageData: boolean
  setShouldFetchUsageData: Dispatch<SetStateAction<boolean>>
  usageData: GetProfileDataResponse | null
  usageDataError: unknown
}
export const UserProfileContext = createContext<UserProfileContextType>({} as UserProfileContextType)

export function UserProfileProvider({ children }: ChildrenProps): JSX.Element {
  const [shouldFetchUsageData, setShouldFetchUsageData] = useState(false)
  const [usageDataError, setUsageDataError] = useState<unknown | null>(null)
  const { data: usageData, isLoading: isFetchingUsageData } = useFetcher<GetProfileDataResponse>({
    allowRefetch: true,
    url: '/chatapi/GetProfileDataApi',
    shouldFetch: shouldFetchUsageData,
    noCache: true,
    payload: {
      language: 'EN',
    },
    onError: (e) => {
      setUsageDataError(e)
    },
    final: () => setShouldFetchUsageData(false),
  })

  return (
    <>
      <UserProfileContext.Provider
        value={{
          isFetchingUsageData,
          setShouldFetchUsageData,
          usageData,
          usageDataError,
        }}
      >
        {children}
      </UserProfileContext.Provider>
    </>
  )
}

export const useUserProfileContext = (): UserProfileContextType => useContext(UserProfileContext)
