import { ChangeEvent, Dispatch, SetStateAction, useMemo, useState } from 'react'
import { IconType } from 'react-icons'
import * as BiIcons from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'
import { Checkbox } from '@chakra-ui/checkbox'
import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Text } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'
import { Tooltip } from '@chakra-ui/tooltip'

import { BotIconWithFallback } from 'components/BotIconWithFallback'
import { TextButton } from 'components/buttons/TextButton'
import { TooltipButton } from 'components/buttons/TooltipButton'
import { ConfirmDeleteConversationModal } from 'components/ConfirmDeleteConversation'

import { useBotContext } from 'providers/BotProvider'
import { useKBotContext } from 'providers/KBotsProvider'
import { ConversationType, useMessagesContext } from 'providers/MessageProvider'

import { FEUIConfig } from 'types/types'

import { useI18Context } from '../providers/i18Provider'
import { useSettingsContext } from '../providers/SettingsProvider'

type ChatBotType = {
  icon: IconType | string
  botName: string
  header: string
  type: string
}

export const Conversations = ({ config }: { config: FEUIConfig[] | undefined }) => {
  const { isLightMode } = useSettingsContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { t, getTForNS } = useI18Context()
  const {
    conversations,
    getNumberOfConversations,
    fetchingBotConversationsError,
    getBotConversationsWithId,
    isFetchingBotConversations,
  } = useMessagesContext()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [selectedConversations, setSelectedConversations] = useState<string[]>([])

  const chatBots = useMemo(() => {
    const loadBots = (botConfig: FEUIConfig[]) => {
      const newBots: ChatBotType[] = []

      for (const conf of botConfig) {
        const botT = getTForNS(conf.botName.toLocaleLowerCase())
        let icon: IconType | string = ''

        if (conf.icon) {
          const BiIcon = (BiIcons as Record<string, IconType>)[conf.icon]
          // Check if the component exists in the 'react-icons' library
          if (BiIcon) {
            icon = BiIcon
          } else {
            icon = isLightMode ? '../images/robotBlack.png' : '../images/robotWhite.png'
          }
        } else {
          icon = isLightMode ? '../images/robotBlack.png' : '../images/robotWhite.png'
        }

        if (typeof conf.botName === 'string' && !conf.isHidden) {
          newBots.push({
            icon: icon,
            botName: conf.botName,
            header: botT('home.header'),
            type: conf.type,
          })
        }
      }

      const sortOrder = ['general', 'translation', 'assessment', 'knowledge']

      const sortedBots = newBots.sort((a, b) => {
        return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type)
      })

      return sortedBots
    }

    if (config) {
      return loadBots(config)
    } else return []
  }, [config, getTForNS, isLightMode])

  const numberOfConversations = useMemo(() => {
    return getNumberOfConversations()
  }, [getNumberOfConversations])

  return (
    <Box
      backgroundImage={`${
        isLightMode
          ? 'linear-gradient(rgba(222, 222, 222, 0.8), rgba(222, 222, 222, 0.8)), url(../images/geometry.webp)'
          : 'linear-gradient(rgba(41, 41, 41, 0.7), rgba(41, 41, 41, 0.7)), url(../images/geometry.webp)'
      }`}
      className="flex flex-col h-full overflow-x-hidden bg-center bg-cover"
    >
      <ConfirmDeleteConversationModal
        conversationsToDelete={selectedConversations}
        isOpen={isOpen}
        onClose={onClose}
        onDelete={() => setSelectedConversations([])}
        showConversationCount={true}
        botNames={chatBots
          .map((bot) => bot.botName)
          .filter((botName) => !!(conversations[botName] && Object.keys(conversations[botName]).length))}
      />
      <Box className="flex flex-col items-center justify-start gap-2 my-2 width-layout">
        <Box className="flex flex-col justify-start w-full mt-2 2xl:mt-0">
          <Text
            as="h1"
            className={`${isLightMode ? 'text-kpmgDarkBlue' : 'text-white'} text-2xl sm:text-3xl md:text-4xl font-opensanscondensed`}
          >
            {t('generic.manageConversations')}
          </Text>
          <Text className="text-sm 2xl:text-left md:text-base" test-id="conversation-count">
            {t('generic.manageConversationsDetails')}
          </Text>
        </Box>
      </Box>
      <Box className={`width-layout border-b opacity-50 ${isLightMode ? 'border-kpmgGray2' : 'border-kpmgGray4'}`} />
      <Box className="relative flex flex-col flex-grow overflow-hidden">
        <Box className="flex flex-col flex-grow overflow-hidden width-layout">
          <Box className="h-full overflow-y-auto">
            <Box className="flex flex-col items-center justify-center gap-4 py-4">
              {isFetchingBotConversations && <Spinner className="mt-4" size="lg" />}
              {!isFetchingBotConversations && numberOfConversations === 0 && !fetchingBotConversationsError ? (
                <Text>{t('generic.conversationsEmpty')}</Text>
              ) : fetchingBotConversationsError ? (
                <Box
                  className={`shadow-xl min-w-min flex items-center flex-col rounded m-4 md:m-8 ${
                    isLightMode ? 'bg-white bg-opacity-80' : 'bg-kpmgDarkBlue bg-opacity-60'
                  }`}
                >
                  <Box className="flex flex-col p-4 text-center md:p-6">
                    <Text className="mb-2 text-red-600 md:text-lg">{t('generic.errorFetchingConversations')}</Text>
                    <Text className={`md:text-lg ${isLightMode ? 'text-black' : 'text-white'}`}>
                      {t('generic.failedFetchingConversations')}
                    </Text>
                  </Box>
                </Box>
              ) : (
                !isFetchingBotConversations && (
                  <>
                    {chatBots.map((bot) => {
                      const botConversations = getBotConversationsWithId(bot.botName).filter(
                        (conversation) => conversation.conversationID !== 'temp'
                      )
                      if (botConversations.length) {
                        return (
                          <ChatBotConversationsItem
                            botName={bot.botName}
                            header={bot.header}
                            icon={bot.icon}
                            type={bot.type}
                            key={`${bot.botName}-conversations`}
                            conversations={botConversations}
                            setSelectedConversations={setSelectedConversations}
                            selectedConversations={selectedConversations}
                          />
                        )
                      } else return null
                    })}
                  </>
                )
              )}
            </Box>
          </Box>
          <Box className={`border-b opacity-50 ${isLightMode ? 'border-kpmgGray2' : 'border-kpmgGray4'}`} />
        </Box>
        <Box className="flex flex-row justify-end gap-2 my-4 mb-4 width-layout min-h-min">
          <TextButton
            isDisabled={!selectedConversations.length || isFetchingBotConversations || fetchingBotConversationsError}
            onClick={onOpen}
          >
            {t('controls.deleteSelected')}
          </TextButton>
          <TextButton
            isDisabled={!numberOfConversations || isFetchingBotConversations || fetchingBotConversationsError}
            onClick={() => {
              const newSelections: string[] = []
              chatBots.forEach((bot) => {
                const botConversations = getBotConversationsWithId(bot.botName)
                if (botConversations) {
                  Object.values(botConversations).forEach((conversation) => {
                    if (conversation.conversationID !== 'temp') newSelections.push(conversation.conversationID)
                  })
                }
              })
              setSelectedConversations(newSelections)
              onOpen()
            }}
          >
            {t('controls.deleteAll')}
          </TextButton>
          <Box className="pr-0 ml-1 border-l border-kpmgGray4">&nbsp;</Box>
          <TooltipButton
            button={
              <TextButton
                onClick={() => {
                  if (location.state?.allowReturn) {
                    navigate(-1)
                  } else {
                    navigate('/home')
                  }
                }}
              >
                {t('controls.cancel')}
              </TextButton>
            }
            label={t('generic.cancelManage')}
          />
        </Box>
      </Box>
    </Box>
  )
}

type ChatBotConversationsItemProps = {
  icon: IconType | string
  header: string
  botName: string
  type: string
  conversations: Array<
    ConversationType & {
      conversationID: string
    }
  >
  selectedConversations: string[]
  setSelectedConversations: Dispatch<SetStateAction<string[]>>
  key: string
}

export const ChatBotConversationsItem = (props: ChatBotConversationsItemProps) => {
  const { conversations, icon, key, header, botName, selectedConversations, setSelectedConversations, type } = props
  const { isLightMode } = useSettingsContext()

  const { kBotData } = useKBotContext()

  const { getStreamingStatus } = useBotContext()

  const { t, languageAbbreviation } = useI18Context()

  const disableBotConversationDelete = useMemo(() => {
    return !!getStreamingStatus(botName)?.status
  }, [botName, getStreamingStatus])

  const getStripeColor = () => {
    if (type === 'general') {
      return 'bg-green-500'
    } else if (type === 'knowledge') {
      return 'bg-kpmgPacificBlue'
    }
    return 'bg-kpmgPurple'
  }

  const someSelected = useMemo(() => {
    return conversations
      .map((conv) => conv.conversationID)
      .filter((id) => id !== 'temp')
      .some((id) => selectedConversations.includes(id))
  }, [conversations, selectedConversations])

  const allSelected = useMemo(() => {
    return conversations
      .map((conv) => conv.conversationID)
      .filter((id) => id !== 'temp')
      .every((id) => selectedConversations.includes(id))
  }, [conversations, selectedConversations])

  const handleSelectAllBotConversationsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedConversations((current) => {
      if (e.target.checked) {
        const newSelections = [...current]
        conversations.forEach((conversation) => {
          if (!current.includes(conversation.conversationID)) {
            newSelections.push(conversation.conversationID)
          }
        })
        return newSelections
      } else {
        const newSelections = [...current]
        const botConversationIds = conversations
          .map((conversation) => conversation.conversationID)
          .filter((conversationID) => conversationID !== 'temp')

        return newSelections.filter((idInCurrent) => !botConversationIds.includes(idInCurrent))
      }
    })
  }

  const handleConversationClick = (conversationID: string) => {
    setSelectedConversations((current) => {
      if (!selectedConversations.includes(conversationID)) {
        const newSelections = [...current]
        newSelections.push(conversationID)
        return newSelections
      } else {
        const newSelections = [...current]
        return newSelections.filter((idInCurrent) => conversationID !== idInCurrent)
      }
    })
  }

  const handleKbotClick = (
    kBotConversations: Array<ConversationType & { conversationID: string }>,
    isAllSelected: boolean
  ) => {
    setSelectedConversations((current) => {
      if (!isAllSelected) {
        const newSelections = [...current]
        kBotConversations.forEach((conversation) => {
          if (!current.includes(conversation.conversationID)) {
            newSelections.push(conversation.conversationID)
          }
        })
        return newSelections
      } else {
        const newSelections = [...current]
        const botConversationIds = kBotConversations
          .map((conversation) => conversation.conversationID)
          .filter((conversationID) => conversationID !== 'temp')
        return newSelections.filter((idInCurrent) => {
          return !botConversationIds.includes(idInCurrent)
        })
      }
    })
  }

  return (
    <Box
      className="relative flex flex-row w-full h-full overflow-x-hidden overflow-y-hidden transition rounded-lg shadow-lg"
      tabIndex={0}
      key={key}
    >
      <Box className={`absolute left-0 top-0 w-2 h-full rounded-l-lg ${getStripeColor()}`}>&nbsp;</Box>
      <Box
        className={`${isLightMode && 'bg-white'} flex flex-col justify-between flex-1 p-3 pl-4 border border-l-0 rounded-r-lg md:p-4 md:pl-6 border-box`}
      >
        <Box>
          <Box className="flex flex-row items-start justify-between mb-2 space-x-3 space-y-0">
            <Box className="flex flex-row items-center gap-2">
              <Box className="flex-shrink-0">
                <BotIconWithFallback
                  alt={`${botName}-icon`}
                  fallbackContent={icon}
                  botName={botName}
                  botType={type}
                  className="w-6 h-6 md:w-8 md:h-8"
                />
              </Box>
              <Text as="h2" className="text-base font-black text-left md:text-lg">
                {header}
              </Text>
            </Box>
            <Checkbox
              isDisabled={disableBotConversationDelete}
              onChange={(e) => {
                if (!disableBotConversationDelete) {
                  handleSelectAllBotConversationsChange(e)
                }
              }}
              isIndeterminate={someSelected && !allSelected}
              isChecked={allSelected}
            />
          </Box>
          <Box>
            {type === 'general' ? (
              Object.entries(
                conversations.reduce<{ [key: string]: Array<ConversationType & { conversationID: string }> }>(
                  (acc, conversation) => {
                    const { conversationID, template, templateId } = conversation
                    const groupKey =
                      (template?.[languageAbbreviation] ||
                        // If we don't have a template, try to match the ID to an available K-Bot
                        kBotData?.botConfigs.find((config) => config.templateId === templateId)?.template?.[
                          languageAbbreviation
                        ]) ??
                      'noTemplate'
                    if (!acc[groupKey] && conversationID !== 'temp') {
                      acc[groupKey] = []
                    }
                    if (conversationID !== 'temp') {
                      acc[groupKey].push(conversation)
                    }
                    return acc
                  },
                  {}
                )
              ).map(([template, conversationList], kbotIndex) => {
                const someKbotConvSelected = conversationList
                  .map((conv) => conv.conversationID)
                  .some((id) => selectedConversations.includes(id))
                const allKbotConvSelected = conversationList
                  .map((conv) => conv.conversationID)
                  .every((id) => selectedConversations.includes(id))

                return (
                  <Box key={template} className={kbotIndex === 0 ? 'my-4' : 'mb-4'}>
                    {/* Section heading for templateId */}
                    <Box className="flex flex-row items-center justify-start mb-2 ">
                      <Text
                        as="span"
                        className={`pr-2 text-xs font-bold cursor-pointer ${disableBotConversationDelete && 'cursor-not-allowed'}`}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          if (!disableBotConversationDelete) {
                            handleKbotClick(conversationList, allKbotConvSelected)
                          }
                        }}
                      >
                        {template === 'noTemplate' ? t('kBots.other') : template || t('kBots.failedToFindKBotName')}
                      </Text>
                      <Checkbox
                        isDisabled={disableBotConversationDelete}
                        onChange={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          if (!disableBotConversationDelete) {
                            handleKbotClick(conversationList, allKbotConvSelected)
                          }
                        }}
                        isIndeterminate={someKbotConvSelected && !allKbotConvSelected}
                        isChecked={allKbotConvSelected}
                      />
                    </Box>
                    <Box className="flex flex-row flex-wrap gap-4">
                      {/* Render conversations for this templateId */}
                      {conversationList.map((item, conversationListIndex) => {
                        const { conversationID, displayName } = item
                        if (displayName) {
                          return (
                            <ConversationListItem
                              disabled={disableBotConversationDelete}
                              displayName={displayName}
                              conversationID={conversationID}
                              conversationListIndex={conversationListIndex}
                              handleConversationClick={handleConversationClick}
                              selectedConversations={selectedConversations}
                            />
                          )
                        }
                        return null
                      })}
                    </Box>
                  </Box>
                )
              })
            ) : (
              <Box className="flex flex-row flex-wrap gap-4 mt-4">
                {conversations.map((item, conversationListIndex) => {
                  const { conversationID, displayName } = item
                  if (displayName) {
                    return (
                      <ConversationListItem
                        disabled={disableBotConversationDelete}
                        displayName={displayName}
                        conversationID={conversationID}
                        conversationListIndex={conversationListIndex}
                        handleConversationClick={handleConversationClick}
                        selectedConversations={selectedConversations}
                      />
                    )
                  }
                  return null
                })}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

type ConversationListItemType = {
  disabled: boolean
  displayName: string
  conversationID: string
  conversationListIndex: number
  handleConversationClick: (conversationID: string) => void
  selectedConversations: string[]
}

const ConversationListItem = ({
  disabled,
  displayName,
  conversationID,
  conversationListIndex,
  handleConversationClick,
  selectedConversations,
}: ConversationListItemType) => {
  const { isLightMode } = useSettingsContext()
  const { t } = useI18Context()
  return (
    <Tooltip
      label={disabled ? t('generic.currentlyStreaming') : displayName}
      placement="top-start"
      className="text-xs text-black bg-kpmgGray5 line-clamp-3"
    >
      <Box
        className={`flex justify-between mb-2 text-xs font-normal text-left border rounded-md md:text-sm px-2 py-2.5 cursor-pointer ${
          isLightMode && 'border-kpmgDarkBlue'
        } ${disabled && 'cursor-not-allowed'}`}
        key={`${conversationID}_${conversationListIndex}`}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          if (!disabled) {
            handleConversationClick(conversationID)
          }
        }}
      >
        <Text className="pr-2 truncate max-w-48 whitespace-nowrap">{displayName}</Text>
        <Checkbox
          isDisabled={disabled}
          onChange={(e) => {
            e.stopPropagation()
            e.preventDefault()
            if (!disabled) {
              handleConversationClick(conversationID)
            }
          }}
          isChecked={selectedConversations.includes(conversationID)}
        ></Checkbox>
      </Box>
    </Tooltip>
  )
}
