import { useEffect, useMemo, useState } from 'react'
import type { IconType } from 'react-icons'
import * as BiIcons from 'react-icons/bi'
import { Button } from '@chakra-ui/button'
import { Image } from '@chakra-ui/image'
import { Input } from '@chakra-ui/input'
import { Box } from '@chakra-ui/layout'
import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger } from '@chakra-ui/popover'

import { IconButton } from 'components/buttons/IconButton'

import { useI18Context } from 'providers/i18Provider'
import { useSettingsContext } from 'providers/SettingsProvider'

export const useIconsMenu = ({
  iconValue,
  isDisabled,
  onIconChange,
  mode,
}: {
  iconValue?: string | undefined
  isDisabled: boolean
  onIconChange: (icon: string) => void
  mode: 'create' | 'edit'
}) => {
  const { isLightMode } = useSettingsContext()
  const { t } = useI18Context()

  const [iconSearchValue, setIconSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const iconListAsStrings = useMemo(() => {
    const icons = []
    for (const key in BiIcons) {
      icons.push(key)
    }

    return icons
  }, [])

  const searchedIconList = useMemo(() => {
    return iconListAsStrings.filter((item) => item.toLowerCase().includes(iconSearchValue.toLowerCase()))
  }, [iconListAsStrings, iconSearchValue])

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  const openPopover = () => {
    setIsOpen(true)
  }

  const closePopover = () => {
    setIsOpen(false)
  }

  const IconsMenu = useMemo(
    () => (
      <Popover isLazy isOpen={isOpen} onClose={closePopover}>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Button isDisabled={isDisabled} className="p-2 max-w-11 max-h-11" onClick={openPopover}>
                {(() => {
                  // if no icon selected, show the robot
                  if (!iconValue) {
                    return (
                      <Image
                        src={isLightMode ? '../images/robotBlack.png' : '../images/robotWhite.png'}
                        alt="Robot"
                        className="w-full h-full"
                      />
                    )
                  }

                  const BiIcon = (BiIcons as Record<string, IconType>)[iconValue]

                  return <BiIcon className="w-full h-full" />
                })()}
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverHeader>
                <Box className="flex flex-row gap-2">
                  <Input
                    onChange={(e) => setIconSearchValue(e.target.value)}
                    placeholder={t('kBots.createEdit.searchIcons')}
                    value={iconSearchValue}
                  />
                  <IconButton
                    onClick={onClose}
                    iconName={BiIcons.BiX}
                    aria-label={'close-icons-menu'}
                    iconClassName="text-2xl"
                  />
                </Box>
              </PopoverHeader>
              <PopoverArrow />
              <PopoverBody>
                <Box className="grid h-40 grid-cols-6 p-0 overflow-y-scroll">
                  {' '}
                  {searchedIconList.map((componentName, index) => {
                    const BiIcon = (BiIcons as Record<string, IconType>)[componentName]
                    return (
                      <Box
                        key={`icon-${index}`}
                        className="flex items-center justify-center col-span-1 p-2 cursor-pointer max-h-8"
                        onClick={() => {
                          onIconChange(componentName)
                          onClose()
                        }}
                      >
                        <BiIcon />
                      </Box>
                    )
                  })}
                </Box>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    ),
    [iconSearchValue, iconValue, isDisabled, isLightMode, isOpen, onIconChange, searchedIconList, t]
  )

  return {
    IconsMenu,
  }
}
