import { LRUCache } from 'lru-cache'

// eslint-disable-next-line
const cache = new LRUCache<string, any>({ ttl: 60 * 60 * 1000, max: 30 })

const useLRUCache = <T, K>(): [(key: K[]) => Promise<T | null>, (key: K[], value: T) => Promise<void>] => {
  const generateKey = async (data: object): Promise<string> => {
    const encoder = new TextEncoder()
    const dataString = JSON.stringify(data)
    const dataBuffer = encoder.encode(dataString)
    const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
    return hashHex
  }

  const getCachedValue = async (dataKey: K[]): Promise<T | null> => {
    const key = await generateKey(dataKey)
    return cache.get(key) || null
  }

  const setCachedValue = async (dataKey: K[], value: T): Promise<void> => {
    const key = await generateKey(dataKey)
    cache.set(key, value)
  }

  return [getCachedValue, setCachedValue]
}

export { useLRUCache }
