import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { BiEdit } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { SingleValue } from 'react-select'
import { FormLabel } from '@chakra-ui/form-control'
import { useDisclosure } from '@chakra-ui/hooks'
import { Input } from '@chakra-ui/input'
import { Box, Text } from '@chakra-ui/layout'
import { SkeletonCircle, SkeletonText } from '@chakra-ui/skeleton'
import { VisuallyHidden } from '@chakra-ui/visually-hidden'
import { KBotBaseConfig } from '@kleo/types'

import { TextIconButton } from 'components/buttons/TextIconButton'
import { TooltipButton } from 'components/buttons/TooltipButton'
import { LoadMoreGrid } from 'components/LoadMoreGrid'
import { BasicSelect } from 'components/Select'

import { useI18Context } from 'providers/i18Provider'
import { useKBotContext } from 'providers/KBotsProvider'
import { useSettingsContext } from 'providers/SettingsProvider'
import { useThemeContext } from 'providers/ThemeProvider'

import { SearchFilterItem } from 'types/types'

import { ViewKBotModal } from './ViewKBotModal'

export const ViewKBots = () => {
  const { t, languageAbbreviation } = useI18Context()
  const { kBots, systemKBots, isFetchingKBots, fetchKBotsError } = useKBotContext()
  const { isOpen: isViewKBotOpen, onOpen: onViewKBotOpen, onClose: onViewKBotClose } = useDisclosure()
  const { isLightMode } = useSettingsContext()

  const [userBotsSortingOrder, setUserBotsSortingOrder] = useState<SingleValue<SearchFilterItem>>({
    value: 'newest',
    label: t('kBots.createEdit.newest'),
  })
  const [systemBotsSortingOrder, setSystemBotsSortingOrder] = useState<SingleValue<SearchFilterItem>>({
    value: 'newest',
    label: t('kBots.createEdit.newest'),
  })
  const [currentViewingBot, setCurrentViewingBot] = useState<null | KBotBaseConfig>(null)

  const sortingOptions = useMemo(
    () => [
      {
        value: 'az',
        label: `${t('kBots.createEdit.name')} (${t('generic.az')})`,
      },
      {
        value: 'za',
        label: `${t('kBots.createEdit.name')} (${t('generic.za')})`,
      },
      {
        value: 'newest',
        label: t('kBots.createEdit.newest'),
      },
      {
        value: 'oldest',
        label: t('kBots.createEdit.oldest'),
      },
    ],
    [t]
  )

  const sortBots = useCallback(
    (bots: KBotBaseConfig[], sortingMethod: SearchFilterItem) => {
      const sortedBots = [...bots]
      if (sortingMethod?.value === 'za') {
        // Name ascending (Z-A)
        sortedBots.sort((a, b) =>
          a.template[languageAbbreviation].toLowerCase() < b.template[languageAbbreviation].toLowerCase()
            ? 1
            : b.template[languageAbbreviation].toLowerCase() < a.template[languageAbbreviation].toLowerCase()
              ? -1
              : 0
        )
      } else if (sortingMethod?.value === 'oldest') {
        // Date oldest to newest
        sortedBots.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0))
      } else if (sortingMethod?.value === 'newest') {
        // Date newest to oldest
        sortedBots.sort((a, b) => (a.date < b.date ? 1 : b.date < a.date ? -1 : 0))
      } else {
        // Name descending (A-Z)
        sortedBots.sort((a, b) =>
          a.template[languageAbbreviation].toLowerCase() > b.template[languageAbbreviation].toLowerCase()
            ? 1
            : b.template[languageAbbreviation].toLowerCase() > a.template[languageAbbreviation].toLowerCase()
              ? -1
              : 0
        )
      }
      return sortedBots
    },
    [languageAbbreviation]
  )

  const userBots = useMemo(() => {
    let userBots = kBots?.filter((kBot) => kBot.source === 'user') ?? []
    if (userBotsSortingOrder) {
      userBots = sortBots(userBots, userBotsSortingOrder)
    }
    return userBots
  }, [kBots, sortBots, userBotsSortingOrder])

  const sortedSystemBots = useMemo(() => {
    let generalFirstSystemKBots = [...systemKBots]
    const generalIndex = generalFirstSystemKBots.findIndex((config) => config.name === 'general')
    const [generalConfig] = generalFirstSystemKBots.splice(generalIndex, 1)

    if (systemBotsSortingOrder) {
      generalFirstSystemKBots = sortBots(generalFirstSystemKBots, systemBotsSortingOrder)
    }

    if (!generalConfig && !generalFirstSystemKBots.length) {
      return []
    }

    return [generalConfig, ...generalFirstSystemKBots]
  }, [sortBots, systemBotsSortingOrder, systemKBots])

  useEffect(() => {
    if (currentViewingBot) {
      onViewKBotOpen()
    }
  }, [currentViewingBot, onViewKBotOpen])

  // When the language of the application changes, make sure we update the labels of the "Sort By" Select
  useEffect(() => {
    setSystemBotsSortingOrder((currentSystemBotsSortingOrder) => {
      if (currentSystemBotsSortingOrder) {
        return {
          ...currentSystemBotsSortingOrder,
          label: t(`kBots.createEdit.${currentSystemBotsSortingOrder.value}`),
        }
      }
      return currentSystemBotsSortingOrder
    })
    setUserBotsSortingOrder((currentUserBotsSortingOrder) => {
      if (currentUserBotsSortingOrder) {
        return {
          ...currentUserBotsSortingOrder,
          label: t(`kBots.createEdit.${currentUserBotsSortingOrder.value}`),
        }
      }
      return currentUserBotsSortingOrder
    })
  }, [languageAbbreviation, t])

  return (
    <Box
      className={`flex flex-col w-full h-full p-3 overflow-y-auto rounded-lg md:p-4 ${isLightMode ? 'bg-white' : 'bg-kpmgDarkBlue'}`}
    >
      {currentViewingBot && (
        <ViewKBotModal
          kBotToFetch={currentViewingBot}
          isOpen={isViewKBotOpen}
          onClose={() => {
            setCurrentViewingBot(null)
            onViewKBotClose()
          }}
        />
      )}
      <Box className="mb-4">
        <Text
          as="h1"
          className={`mb-2 text-2xl md:text-3xl lg:text-4xl font-opensanscondensed ${isLightMode ? 'text-kpmgBlue' : 'text-white'}`}
        >
          {t('kBots.viewKBots')}
        </Text>
        <Text as="h2" className={`text-sm md:text-base ${isLightMode ? 'text-kpmgGray2' : 'text-kpmgGray4'}`}>
          {t('kBots.viewKBotsDescription')}
        </Text>
        <Box className={`h-[1px] w-full mt-2 bg-opacity-75 ${isLightMode ? 'bg-kpmgGray5' : 'bg-kpmgGray3'}`} />
      </Box>
      <Box className="mb-6">
        <ViewKBotSection
          availableBots={userBots}
          fetchKBotsError={fetchKBotsError}
          isFetchingKBots={isFetchingKBots}
          setCurrentViewingBot={setCurrentViewingBot}
          setSortingOrder={setUserBotsSortingOrder}
          sortingOptions={sortingOptions}
          sortingOrder={userBotsSortingOrder}
          title={t('kBots.myKBots')}
        />
      </Box>
      <ViewKBotSection
        availableBots={sortedSystemBots}
        fetchKBotsError={fetchKBotsError}
        isFetchingKBots={isFetchingKBots}
        isSystemKBots
        setCurrentViewingBot={setCurrentViewingBot}
        setSortingOrder={setSystemBotsSortingOrder}
        sortingOptions={sortingOptions}
        sortingOrder={systemBotsSortingOrder}
        title={t('kBots.systemKBots')}
      />
    </Box>
  )
}

type ViewKBotSectionProps = {
  availableBots: KBotBaseConfig[]
  fetchKBotsError: string | null
  isFetchingKBots: boolean
  isSystemKBots?: boolean
  setCurrentViewingBot: Dispatch<SetStateAction<KBotBaseConfig | null>>
  setSortingOrder: (value: SetStateAction<SingleValue<SearchFilterItem>>) => void
  sortingOptions: {
    value: string
    label: string
  }[]
  sortingOrder: SingleValue<SearchFilterItem>
  title: string
}

const ViewKBotSection = (props: ViewKBotSectionProps) => {
  const {
    availableBots,
    fetchKBotsError,
    isFetchingKBots,
    isSystemKBots = false,
    setCurrentViewingBot,
    setSortingOrder,
    sortingOptions,
    sortingOrder,
    title,
  } = props
  const { isLargeDesktop, isLaptop, isTablet, isMobile } = useThemeContext()
  const { t } = useI18Context()
  const navigate = useNavigate()
  const { isLightMode } = useSettingsContext()

  const [searchInput, setSearchInput] = useState<string>('')

  const filteredKBots = useMemo(() => {
    if (searchInput) {
      return availableBots.filter(
        (bot) =>
          bot.template.en.toLowerCase().includes(searchInput.toLowerCase()) ||
          bot.template.fr.toLowerCase().includes(searchInput.toLowerCase())
      )
    }
    return availableBots
  }, [availableBots, searchInput])

  return (
    <Box className="flex flex-col">
      <Box className="flex flex-wrap items-center justify-between gap-2 mb-2 md:items-end">
        <Text as="h2" className="pl-1 text-lg md:text-xl lg:text-2xl font-opensanscondensed">
          {title}
        </Text>
        <Box className="flex flex-row flex-wrap items-center gap-2">
          <VisuallyHidden>
            <FormLabel htmlFor={`search-${title.toLowerCase().replace(/\s+/g, '-')}`}>{`Search ${title}`}</FormLabel>
          </VisuallyHidden>
          <Input
            id={`search-${title.toLowerCase().replace(/\s+/g, '-')}`}
            className={`text-sm border-[#CCCCCC] ${!isMobile && 'flex-grow basis-0'}`}
            placeholder={t('kBots.searchKBots')}
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
          />
          <BasicSelect
            options={sortingOptions}
            value={sortingOrder}
            onChange={(selectedOption: SingleValue<SearchFilterItem> | null) => {
              setSortingOrder(selectedOption)
            }}
            className={!isMobile ? 'flex-shrink-0 min-w-content' : 'w-full'}
          />
        </Box>
      </Box>
      <Box
        className={`flex flex-col flex-grow rounded-lg bg-gradient-to-r bg-[length:200%_200%] animate-[gradient-move_25s_ease_infinite] ${isLightMode ? 'from-kpmgPacificBlue via-kpmgLightBlue to-kpmgLightPurple' : 'from-kpmgBlue via-kpmgCobaltBlue to-kpmgPurple'}`}
      >
        {isFetchingKBots ? (
          <Box
            className={`grid w-full h-full grid-cols-${isLargeDesktop ? 4 : isLaptop ? 3 : isTablet ? 2 : 1} grid-rows-${isLaptop ? 2 : isTablet ? 3 : 6} gap-2 md:gap-3 grid p-2 md:p-3`}
          >
            {[...Array(isLargeDesktop ? 8 : isLaptop ? 6 : isTablet ? 6 : 6)].map((e, index) => (
              <Box
                className={`flex flex-col p-4 rounded-lg shadow-lg ${isLightMode ? 'bg-white' : 'bg-kpmgDarkBlue'}`}
                key={`${e}-${index}`}
              >
                <Box className="flex flex-row items-center mb-2 space-x-3">
                  <SkeletonCircle size={isTablet ? '8' : '6'} />
                  <Box className="flex-grow">
                    <SkeletonText noOfLines={1} spacing="2" skeletonHeight="4" />
                  </Box>
                </Box>
                <SkeletonText noOfLines={4} spacing="2" skeletonHeight="2" />
              </Box>
            ))}
          </Box>
        ) : fetchKBotsError ? (
          <Box
            className={`flex w-full rounded-lg p-2 md:p-3 ${isLightMode ? 'bg-gray-100 text-red-600' : 'bg-kpmgDarkBlueHover text-red-400'}`}
          >
            {t(`kBots.error.${fetchKBotsError}`)}
          </Box>
        ) : Array.isArray(availableBots) && availableBots.length ? (
          filteredKBots.length ? (
            <LoadMoreGrid
              numColumns={isLargeDesktop ? 4 : isLaptop ? 3 : isTablet ? 2 : 1}
              numRows={isLaptop ? 2 : isTablet ? 3 : 6}
              gridItems={filteredKBots}
              setCurrentViewingBot={setCurrentViewingBot}
            />
          ) : (
            <Text className="p-2 text-center md:p-3">{t('kBots.noKBotsMatchSearch')}</Text>
          )
        ) : (
          <Box className="flex flex-col items-center justify-center flex-grow px-2 py-4 text-center md:py-8">
            {isSystemKBots ? (
              <Text>{t('kBots.createEdit.noSystemBots')}</Text>
            ) : (
              <>
                <Text className="mb-2">{t('kBots.createEdit.noUserBots')}</Text>
                <TooltipButton
                  button={
                    <TextIconButton
                      aria-label="clearMessages"
                      className="ml-4 text-white bg-kpmgBlue hover:bg-kpmgBlueHover"
                      leftIcon={<BiEdit />}
                      onClick={() => navigate('/k-bots/createKBot')}
                      test-id="clear-messages-button"
                      variant={isLightMode ? 'kpmgWhiteBlueText' : 'kpmgDarkBlue'}
                    >
                      {t('controls.create')}
                    </TextIconButton>
                  }
                  label={t('kBots.createBot')}
                />
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}
