import { useEffect, useMemo, useRef, useState } from 'react'
import { BiFileBlank, BiInfoCircle, BiX } from 'react-icons/bi'
import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Text } from '@chakra-ui/layout'
import { Tooltip } from '@chakra-ui/tooltip'

import { Banner } from 'components/Banner'
import { IconButton } from 'components/buttons/IconButton'
import { TextButton } from 'components/buttons/TextButton'
import { TooltipButton } from 'components/buttons/TooltipButton'
import { ClearFilesBody, ClearFilesFooter } from 'components/ClearFilesForm'
import { HTMLStringParser } from 'components/HTMLStringParser'
import { ModalBox } from 'components/Modal'
import { UploadModal } from 'components/UploadModal'

import { useI18Context } from 'providers/i18Provider'
import { useSettingsContext } from 'providers/SettingsProvider'
import { useThemeContext } from 'providers/ThemeProvider'
import { useUploadContext } from 'providers/UploadProvider'

type UploadWithPreviewProps = {
  allowMultipleFiles?: boolean
  botName: string
  currentConversationID: string
  isDisabled?: boolean
  isExpanded: boolean
  isFetchingChart: boolean
  isStreaming: boolean
  kBotId?: string
  onClear?: () => void // an optional callback function to perform after clearing the content
  onSubmit: (files: File[]) => void
  supportedFileExtensions: Array<string>
  supportedMimeTypes: Array<string>
  uploadTokenMaxSize: number
}

export const UploadWithPreview = ({
  allowMultipleFiles = false,
  botName,
  currentConversationID,
  isDisabled,
  isExpanded,
  isFetchingChart,
  isStreaming,
  kBotId,
  onClear,
  onSubmit,
  supportedFileExtensions,
  supportedMimeTypes,
  uploadTokenMaxSize,
}: UploadWithPreviewProps) => {
  const {
    deleteDocumentContent,
    docContents,
    isFileUploading,
    maxFileNameLength,
    maxFileSize,
    maxSizeOCR,
    setUploadError,
    uploadError,
    uploadFileMaxCount,
    uploadModalOperations: { onOpen, isOpen, onClose },
  } = useUploadContext()

  const scrollRef = useRef<HTMLDivElement | null>(null) // use this to scroll to the top of the file contents when the content changes

  const { language, t } = useI18Context()
  const { isTablet } = useThemeContext()
  const { isOpen: isClearFileOpen, onOpen: onClearFileOpen, onClose: onClearFileClose } = useDisclosure()
  const { isLightMode } = useSettingsContext()

  const [isFileSizeWarningAcknowledged, setIsFileSizeWarningAcknowledged] = useState<boolean>(false)

  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat(language)
  }, [language])

  const documentContentsToRender = useMemo(() => {
    if (botName in docContents && currentConversationID in docContents[botName]) {
      return docContents[botName][currentConversationID]
    }
  }, [currentConversationID, botName, docContents])

  const shouldShowFileSizeContentWarning = useMemo(() => {
    const fileTypesThatUseOCR = ['docx', 'gif', 'jpeg', 'jpg', 'pdf', 'pdf', 'png', 'pptx']
    return !!(
      !isFileSizeWarningAcknowledged &&
      documentContentsToRender &&
      documentContentsToRender.size > maxSizeOCR.value &&
      !documentContentsToRender.usedOCR &&
      fileTypesThatUseOCR.includes(documentContentsToRender.type)
    )
  }, [documentContentsToRender, isFileSizeWarningAcknowledged, maxSizeOCR.value])

  useEffect(() => {
    if (documentContentsToRender && documentContentsToRender?.text && scrollRef.current) {
      scrollRef.current.scrollTop = 0
    }
  }, [documentContentsToRender, documentContentsToRender?.text])

  const clearFileContents = () => {
    onClearFileClose()

    if (botName in docContents && currentConversationID in docContents[botName]) {
      deleteDocumentContent([botName], [currentConversationID])
      onClear && onClear()
      return
    }
  }
  return (
    <>
      <UploadModal
        botName={botName}
        isFileUploading={isFileUploading}
        isOpen={isOpen}
        kBotId={kBotId}
        maxFileNameLength={maxFileNameLength}
        maxFileSize={maxFileSize}
        maxNumFiles={uploadFileMaxCount}
        onClose={onClose}
        onError={(error: string) => setUploadError(error)}
        onSubmit={onSubmit}
        supportedFileExtensions={supportedFileExtensions}
        supportedMimeTypes={supportedMimeTypes}
        uploadError={uploadError}
        uploadTokenMaxSize={uploadTokenMaxSize}
      />
      {allowMultipleFiles && (
        <ModalBox
          isOpen={isClearFileOpen}
          modalBody={<ClearFilesBody />}
          modalFooter={<ClearFilesFooter onClose={onClearFileClose} primaryButtonOnClick={clearFileContents} />}
          modalHeader={t('generic.clearFiles')}
          onClose={onClearFileClose}
        />
      )}
      <Box
        className={`w-full box-content flex ${
          isExpanded && isTablet ? 'flex-row justify-between items-center' : 'flex-col justify-center'
        }`}
      >
        <Box className="flex">
          <TextButton
            aria-label="upload-file"
            className="mr-2"
            isDisabled={isStreaming || isFetchingChart || isFileUploading || isDisabled}
            isLoading={isFileUploading}
            loadingText={t('uploadModal.uploadingCTA')}
            onClick={() => onOpen()}
          >
            {allowMultipleFiles && documentContentsToRender
              ? t('uploadModal.uploadAnother')
              : t('uploadModal.uploadCTA')}
          </TextButton>
          {allowMultipleFiles && (
            <TooltipButton
              button={
                <IconButton
                  aria-label="clearFiles"
                  iconClassName="text-2xl"
                  iconName={BiX}
                  isDisabled={isStreaming || isFetchingChart || !documentContentsToRender}
                  onClick={() => onClearFileOpen()}
                  test-id="clear-messages-button"
                />
              }
              label={t('generic.clearFiles')}
            />
          )}
        </Box>
        <Box className={`flex-1 ${isExpanded && isTablet ? 'ml-4' : 'mt-4'}`}>
          {documentContentsToRender ? (
            <Box className="flex items-center">
              <BiFileBlank className="w-6 h-6" />
              <Text as="span" className="ml-1 text-xs">
                {`${numberFormat.format(documentContentsToRender.characterCount ?? 0)} ${t('uploadModal.characters')}`}
              </Text>
              <Tooltip
                className={`text-xs ${isLightMode ? 'text-white' : 'text-black'}`}
                label={t('generic.fileContentCharacterCount')}
                placement="top"
                tabIndex={0}
              >
                <span className="inline-block ml-1">
                  <BiInfoCircle className="text-xs md:text-sm" />
                </span>
              </Tooltip>
            </Box>
          ) : (
            <Box>
              <Text className="text-sm">{t('uploadModal.noFileUploaded')}</Text>
            </Box>
          )}
        </Box>
      </Box>
      <Box className="flex flex-col w-full h-full min-h-0 mt-4">
        <Text className="mb-2 text-sm font-bold md:text-base">{t('uploadModal.uploadedFileContents')}:</Text>
        <Box className="h-full overflow-hidden rounded-xl">
          <Box
            className="h-full min-h-[300px] p-2 overflow-auto rounded-xl bg-kpmgDarkGray5"
            whiteSpace="pre-wrap"
            ref={scrollRef}
          >
            <Banner
              className="mb-4"
              onClick={() => setIsFileSizeWarningAcknowledged(true)}
              title={t('uploadModal.fileSizeWarningHeader')}
              description={
                <Box className="last:mb-0">
                  <Text className="mb-2">{t('uploadModal.fileSizeWarningBody', { maxSizeOCR: maxSizeOCR.mb })}</Text>
                </Box>
              }
              isVisible={shouldShowFileSizeContentWarning}
            />
            {documentContentsToRender && documentContentsToRender?.text ? (
              <Text as="span" className="text-xs text-black">
                <HTMLStringParser htmlString={'<html><body>' + documentContentsToRender.text + '</body></html>'} />
              </Text>
            ) : (
              <Text as="span" className="text-xs text-kpmgGray1">
                {t('uploadModal.noContent')}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
      <Box flex="0 0 auto" />
    </>
  )
}
