import { useState } from 'react'
import * as yup from 'yup'

import { ValidationErrors } from 'types/types'

export const useValidation = <T extends Record<string, unknown>>(schema: yup.ObjectSchema<T>) => {
  const [formErrors, setFormErrors] = useState<ValidationErrors<T>>({})

  const validate = async (values: T) => {
    try {
      await schema.validate(values, { abortEarly: false })
      clearErrors()

      return Promise.resolve()
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const errors: ValidationErrors<T> = {}
        err.inner.forEach((error) => {
          if (error.path && error.message) {
            errors[error.path as keyof T] = error.message
          }
        })
        setFormErrors(errors)
      }
      return Promise.reject()
    }
  }

  const clearErrors = () => {
    setFormErrors({})
  }

  return { formErrors, validate, clearErrors }
}
