export const formattedDate = (date: number, language: string) => {
  return new Date(date).toLocaleDateString(language, {
    month: 'long', // "April"
    day: 'numeric', // "23"
    year: 'numeric', // "2024"
    hour: '2-digit', // "6 PM"
    minute: '2-digit', // "00"
    hour12: true, // AM/PM format
    timeZoneName: 'short', // Displays time zone abbreviation
  })
}
