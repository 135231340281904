import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { replaceIdInRecordBotSpecific } from 'utils/replaceIdInRecord'

import type { ConversationSettings, FEUIConfig } from 'types/types'

export function isKeyOfObject<T extends object>(key: string | number | symbol, obj: T): key is keyof T {
  return key in obj
}

type ConversationSettingsContextType = {
  deleteConversationSettings: (conversationIDsToDelete: string[]) => void
  getConversationSettings: (botName: string, conversationID: string | undefined) => ConversationSettings | undefined
  replaceConversationIdInConversationSettings: (payload: {
    botName: string
    oldConversationId: string
    newConversationId: string
  }) => void
  updateConversationSettings: (botName: string, conversationID: string, newSettings?: ConversationSettings) => void
}

export const ConversationSettingsContext = createContext<ConversationSettingsContextType>(
  {} as ConversationSettingsContextType
)

type ConversationSettingsProviderProps = {
  children?: React.ReactNode
  config?: FEUIConfig[]
}

type ConversationsSettingsType = Record<string, Record<string, ConversationSettings>>

export const ConversationSettingsProvider = ({ children, config }: ConversationSettingsProviderProps) => {
  const [conversationSettings, setConversationSettings] = useState<ConversationsSettingsType>({})
  const [defaultBotSettings, setDefaultBotSettings] = useState<Record<string, ConversationSettings>>({})

  // Set default empty conversation settings
  useEffect(() => {
    config?.forEach((botConfig) => {
      // If we ever get more settings for a conversation, we need to modify this logic
      if (botConfig.saveConversations) {
        setDefaultBotSettings((currentDefaultSettings) => ({
          ...currentDefaultSettings,
          [botConfig.botName]: {
            saveConversation: true,
          },
        }))
        setConversationSettings((currentConversationSettings) => ({
          ...currentConversationSettings,
          [botConfig.botName]: {
            temp: {
              saveConversation: true,
            },
          },
        }))
      }
    })
  }, [config])

  /**
   * Returns the settings of the provided bot-conversation
   * @param {string} botName - The name of the bot
   * @param {string} conversationID - The id of the conversation
   * @returns {ConversationSettings | undefined}
   */
  const getConversationSettings = useCallback(
    (botName: string, conversationID: string | undefined): ConversationSettings | undefined => {
      if (
        isKeyOfObject(botName, conversationSettings) &&
        conversationID &&
        isKeyOfObject(conversationID, conversationSettings[botName])
      ) {
        return conversationSettings[botName][conversationID]
      }
      return undefined
    },
    [conversationSettings]
  )

  /**
   * Updates the settings of the provided bot-conversation, with the provided new values
   * If no new values are provided, updates it with the default values for the provided bot
   * @param {string} botName - The name of the bot
   * @param {string} conversationID - The id of the conversation
   * @param {ConversationSettings | undefined} newSettings - The new settings that the current conversation will be saved to
   * @returns {void}
   */
  const updateConversationSettings = useCallback(
    (botName: string, conversationID: string, newSettings?: ConversationSettings): void => {
      setConversationSettings((currentSettings) => {
        return {
          ...currentSettings,
          [botName]: { ...currentSettings[botName], [conversationID]: newSettings || defaultBotSettings[botName] },
        }
      })
    },
    [defaultBotSettings]
  )

  /**
   * Deletes the settings of the provided bot-conversation
   * @param {string[]} conversationIDsToDelete - The id's of the conversations to delete
   * @returns {void}
   */
  const deleteConversationSettings = useCallback((conversationIDsToDelete: string[]): void => {
    setConversationSettings((currentSettings) => {
      const filteredConversations: ConversationsSettingsType = {}

      for (const key in currentSettings) {
        const conversationRecord = currentSettings[key]
        const filteredRecord: Record<string, ConversationSettings> = {}

        for (const numKey in conversationRecord) {
          if (!conversationIDsToDelete.includes(numKey)) {
            filteredRecord[numKey] = conversationRecord[numKey]
          }
        }

        if (Object.keys(filteredRecord).length > 0) {
          filteredConversations[key] = filteredRecord
        }
      }

      return filteredConversations
    })
  }, [])

  const replaceConversationIdInConversationSettings = (payload: {
    botName: string
    oldConversationId: string
    newConversationId: string
  }) => {
    const { botName, oldConversationId, newConversationId } = payload

    replaceIdInRecordBotSpecific({ botName, oldConversationId, newConversationId, setState: setConversationSettings })
  }

  return (
    <>
      <ConversationSettingsContext.Provider
        value={{
          deleteConversationSettings,
          getConversationSettings,
          replaceConversationIdInConversationSettings,
          updateConversationSettings,
        }}
      >
        {children}
      </ConversationSettingsContext.Provider>
    </>
  )
}

export const useConversationSettingsContext = (): ConversationSettingsContextType =>
  useContext(ConversationSettingsContext)
