import { FetchRequest } from 'types/types'

import { NetworkTimeoutError } from '../appError'
import { sleep } from '../sleep'

import { httpJSON, httpRaw, httpStream } from './http'

export type SendRequestConfig = {
  url: string
  token: string | null
  headers?: Record<string, string>
  setRetrying?: (value: boolean) => void
  payload?: FetchRequest
  timeout?: number
  retries?: number
  rId: string
}

export type StreamRequestConfig = {
  url: string
  token: string | null
  payload: FetchRequest
  setRetrying?: (value: boolean) => void
  retries?: number
  rId: string
}

export const fetchData = async <R>({
  url,
  token,
  headers,
  payload,
  setRetrying,
  timeout = 60000,
  retries = 1,
  rId,
}: SendRequestConfig): Promise<R> => {
  const init = {
    method: 'post',
    ...(payload && { body: JSON.stringify(Object.assign(payload)) }),
    headers: {
      'Content-Type': 'application/json',
      'x-msp-ui-request-id': `${rId}`,
      ...(token && { Authorization: `Bearer ${token}` }),
      ...headers,
    },
  }

  const promises = [httpJSON<R>(url, init, retries, setRetrying), sleep<R>(timeout)]
  const finishedPromise = await Promise.race(promises)
  if (finishedPromise === 'sleepMethod') {
    throw new NetworkTimeoutError('Failed to read from API', undefined, timeout)
  }

  return finishedPromise
}

export const fetchDataRaw = async <R>({
  url,
  token,
  headers,
  payload,
  setRetrying,
  timeout = 60000,
  retries = 1,
  rId,
}: SendRequestConfig): Promise<Response | R> => {
  const init = {
    method: 'post',
    ...(payload && { body: JSON.stringify(Object.assign(payload)) }),
    headers: {
      'Content-Type': 'application/json',
      'x-msp-ui-request-id': `${rId}`,
      ...(token && { Authorization: `Bearer ${token}` }),
      ...headers,
    },
  }

  const promises = [httpRaw(url, init, retries, setRetrying), sleep<R>(timeout)]
  const finishedPromise = await Promise.race(promises)
  if (finishedPromise === 'sleepMethod') {
    throw new NetworkTimeoutError('Failed to read from API', undefined, timeout)
  }

  return finishedPromise
}

export const fetchDataStream = ({
  setRetrying,
  payload,
  url,
  token,
  retries = 1,
  rId,
}: StreamRequestConfig): { response: Promise<Response>; signalController: AbortController } => {
  const signalController = new AbortController()
  const init: RequestInit = {
    method: 'post',
    body: JSON.stringify(Object.assign(payload)),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'x-msp-ui-request-id': `${rId}`,
    },
    signal: signalController.signal,
  }

  return {
    response: httpStream(url, init, retries, setRetrying),
    signalController,
  }
}

export const timeOutAction = async <T>(call: Promise<T>, duration: number, timeoutCallback: () => void): Promise<T> => {
  const promises = [call, sleep<T>(duration)]
  const finishedPromise = await Promise.race(promises)
  if (finishedPromise === 'sleepMethod') {
    timeoutCallback()
  }
  return finishedPromise
}

export const putFileIntoStorage = async ({
  url,
  file,
  headers,
  timeout = 60000,
  rId,
}: {
  url: string
  file: File
  headers: { 'Content-Type': string; 'x-ms-blob-type': 'BlockBlob' }
  timeout?: number
  rId: string
}) => {
  const init = {
    method: 'PUT',
    headers,
    body: file,
    'x-msp-ui-request-id': `${rId}`,
  }
  const retries = 1

  const promises = [httpRaw(url, init, retries), sleep<string>(timeout)]

  const finishedPromise = await Promise.race(promises)
  if (typeof finishedPromise === 'string') {
    if (finishedPromise === 'sleepMethod') {
      throw new NetworkTimeoutError('Failed to upload to storage', undefined, timeout)
    }
    throw new NetworkTimeoutError('Failed to upload to storage', undefined, timeout)
  }

  return finishedPromise
}
