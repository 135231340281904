import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'

import { isFirstDotFilters } from 'utils/filters'

import {
  BotCombinedValues,
  ChatMessage,
  ChildrenProps,
  ConversationSettings,
  FilterValue,
  SearchFilterItem,
} from 'types/types'

import { useBotSpecificContext } from './BotSpecificProvider'
import { useConversationSettingsContext } from './ConversationSettingsProvider'
import { useFiltersContext } from './FiltersAndFormsProvider'
import { useI18Context } from './i18Provider'
import { useKBotContext } from './KBotsProvider'
import { useMessagesContext } from './MessageProvider'

type BotFormErrors = {
  userQuery: string | null
  filters: {
    [filterName: string]: string | null
  }
  folderNameInput?: string | null
  translationTargetLanguage?: string | null
}

type ValidateFormValueType =
  | Extract<SetValueArgs, { value: string }>['value']
  | Extract<SetValueArgs, { value: null | ChatMessage }>['value']
  | Extract<SetValueArgs, { value: { [key: string]: FilterValue } }>['value']
  | Extract<SetValueArgs, { value: FilterValue | boolean | Readonly<SearchFilterItem[]> }>['value']

type ValidateFormFieldType =
  | Extract<SetValueArgs, { field: Extract<keyof BotCombinedValues, 'userQuery'> }>['field']
  | Extract<SetValueArgs, { field: Extract<keyof BotCombinedValues, 'filters'> }>['field']
  | Extract<SetValueArgs, { field: Extract<keyof BotCombinedValues, 'folderNameInput'> }>['field']
  | Extract<SetValueArgs, { field: Extract<keyof BotCombinedValues, 'translationTargetLanguage'> }>['field']
  | Extract<SetValueArgs, { field: Exclude<string, keyof BotCombinedValues> }>['field']

type SetValueArgs =
  | {
      field: Extract<keyof BotCombinedValues, 'userQuery'>
      value: string
      shouldValidate?: boolean
    }
  | {
      field: Extract<keyof BotCombinedValues, 'filters'>
      value: {
        [key: string]: FilterValue
      }
      shouldValidate?: boolean
    }
  | {
      field: Extract<keyof BotCombinedValues, 'folderNameInput'>
      value: string
      shouldValidate?: boolean
    }
  | {
      field: Extract<keyof BotCombinedValues, 'translationTargetLanguage'>
      value: string
      shouldValidate?: boolean
    }
  | {
      field: Exclude<string, keyof BotCombinedValues>
      value: FilterValue | boolean | Readonly<SearchFilterItem[]>
      shouldValidate?: boolean
    }

type BotSpecificFormContextType = {
  clearError: (field: keyof BotFormErrors) => void
  currentConversationID: string
  errorText: string | null
  formErrors: BotFormErrors
  getCombinedValues: () => BotCombinedValues
  getCombinedValue: <K extends keyof BotCombinedValues>(field: K) => BotCombinedValues[K]
  getFilterValue: <K extends keyof FilterValue>(filterName: string, field: K) => FilterValue[K]
  handleSubmit: (userInput?: string) => void
  handleSubmitWithFiles: (files: File[]) => void
  isError: boolean
  isLoading: boolean
  loadingText: string | null
  reset: () => void
  setValue: ({ field, value, shouldValidate }: SetValueArgs) => void
}

export const BotSpecificFormContext = createContext<BotSpecificFormContextType>({} as BotSpecificFormContextType)

type BotSpecificFormProviderProps = {
  botName: string
  conversationsBasedBotValues: BotCombinedValues | undefined
  currentConversationID: string
  handleChatSubmit?: (
    values: BotCombinedValues,
    conversationSettings: ConversationSettings | undefined
  ) => Promise<void>
  handleFileSubmit?: (values: BotCombinedValues, files: File[]) => Promise<void>
  schema: Yup.AnyObjectSchema
} & ChildrenProps

const defaultFormErrors = {
  userQuery: null,
  filters: {},
  folderNameInput: null,
  translationTargetLanguage: null,
}

export const BotSpecificFormProvider = ({
  botName,
  children,
  conversationsBasedBotValues,
  currentConversationID,
  handleChatSubmit,
  handleFileSubmit,
  schema,
}: BotSpecificFormProviderProps) => {
  const {
    deleteSelectedFilterValues,
    deleteSelectedFormValues,
    getSelectedFilterValues,
    getSelectedFormAndFilterValues,
    getSelectedFormValues,
    setSelectedFilterValues,
    updateSelectedFilterValues,
    updateSelectedFormValues,
  } = useFiltersContext()
  const { getConversationSettings } = useConversationSettingsContext()

  const { t } = useI18Context()

  const {
    isCreatingConversation,
    isCreatingConversationError,
    isFetchingConversationData,
    isFetchingConversationDataError,
  } = useMessagesContext()
  const { isFetchingKBots, fetchKBotsError } = useKBotContext()

  const { isKBot } = useBotSpecificContext()

  const [formErrors, setFormErrors] = useState<BotFormErrors>(defaultFormErrors)

  const clearError = useCallback((field: keyof BotFormErrors) => {
    setFormErrors((currentFormErrors) => ({
      ...currentFormErrors,
      [field]: defaultFormErrors[field],
    }))
  }, [])

  useEffect(() => {
    if (currentConversationID || botName) {
      clearError('userQuery')
    }
  }, [clearError, currentConversationID, botName])

  const validateForm = useCallback(
    /*
      when shouldValidate = true, we need to pass in the current field and value for validation, otherwise the state changed (tick)
      will be 1 tick behind
      when shouldValidate = false, we can use the state `conversationsBasedBotValues` as validation
    /*/
    async (field?: ValidateFormFieldType, value?: ValidateFormValueType) => {
      // value not equals undefined to handle 0 length string
      const validation =
        field && value !== undefined ? { ...conversationsBasedBotValues, [field]: value } : conversationsBasedBotValues

      try {
        await schema.validate(validation, { abortEarly: false })
        setFormErrors(defaultFormErrors)
        // clear errors if validation passes
        return true
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = err
          const errors: BotFormErrors = { ...defaultFormErrors }
          validationErrors.inner.forEach((error) => {
            if (error.path) {
              const path = error.path
              if (path === 'userQuery' || path === 'folderNameInput' || path === 'translationTargetLanguage') {
                errors[path] = error.message
              } else {
                // the error of too many filters values selected
                if (error.type === 'totalFilterValidation') {
                  errors['filters']['total'] = error.message

                  // all other filter specific errors
                } else {
                  const fieldKeys = path.split('.')
                  if (isFirstDotFilters(fieldKeys)) {
                    errors[fieldKeys[0]][fieldKeys[1]] = error.message
                  }
                }
              }
            }
          })
          setFormErrors(errors)
        }
        return false
      }
    },
    [conversationsBasedBotValues, schema]
  )

  const setValue = useCallback(
    ({ field, value, shouldValidate = true }: SetValueArgs) => {
      if (shouldValidate) {
        validateForm(field, value)
      }

      switch (field) {
        case 'userQuery':
        case 'folderNameInput':
        case 'translationTargetLanguage': {
          const currentFormValues = getSelectedFormValues(botName, currentConversationID)
          if (currentFormValues && typeof value === 'string') {
            updateSelectedFormValues(botName, currentConversationID, { [field]: value })
          }
          break
        }
        case 'filters': {
          const currentFilterValues = getSelectedFilterValues(botName, currentConversationID)

          if (currentFilterValues) {
            const updatedValues = { ...currentFilterValues, [field]: value as { [key: string]: FilterValue } }
            updateSelectedFilterValues(botName, currentConversationID, updatedValues)
          }
          break
        }
        default: {
          const keys = field.split('.')
          const firstKey = keys[0]
          // if the last string of the dot notation is items (part of FilterValue)
          if (keys.at(-1) === 'items') {
            setSelectedFilterValues((currentFilterValues) => ({
              ...currentFilterValues,
              [botName]: {
                ...currentFilterValues[botName],
                [currentConversationID]: {
                  filters: {
                    ...currentFilterValues[botName][currentConversationID].filters,
                    // assuming that items is always preceded by a field key in filters
                    [keys[keys.length - 2]]: {
                      ...currentFilterValues[botName][currentConversationID].filters[keys[keys.length - 2]],
                      items: value as SearchFilterItem[],
                    },
                  },
                },
              },
            }))
            // if the last string of the dot notation is isChecked (part of FilterValue)
          } else if (keys.at(-1) === 'isChecked') {
            setSelectedFilterValues((currentFilterValues) => ({
              ...currentFilterValues,
              [botName]: {
                ...currentFilterValues[botName],
                [currentConversationID]: {
                  filters: {
                    ...currentFilterValues[botName][currentConversationID].filters,
                    // assuming that items is always preceded by a field key in filters
                    [keys[keys.length - 2]]: {
                      ...currentFilterValues[botName][currentConversationID].filters[keys[keys.length - 2]],
                      isChecked: value as boolean,
                    },
                  },
                },
              },
            }))

            // if first part of dot notation is filters, but NOT just filters
          } else if (firstKey === 'filters') {
            setSelectedFilterValues((currentFilterValues) => ({
              ...currentFilterValues,
              [botName]: {
                ...currentFilterValues[botName],
                [currentConversationID]: {
                  [firstKey]: {
                    ...currentFilterValues[botName][currentConversationID].filters,
                    [keys[1]]: value as FilterValue,
                  },
                },
              },
            }))
          }
          // maybe something else here
        }
      }
    },
    [
      botName,
      currentConversationID,
      getSelectedFilterValues,
      getSelectedFormValues,
      setSelectedFilterValues,
      updateSelectedFilterValues,
      updateSelectedFormValues,
      validateForm,
    ]
  )

  const getCombinedValues = useCallback(() => {
    return getSelectedFormAndFilterValues(botName, currentConversationID)
  }, [botName, currentConversationID, getSelectedFormAndFilterValues])

  const getCombinedValue = useCallback(
    <K extends keyof BotCombinedValues>(field: K): BotCombinedValues[K] => {
      return getSelectedFormAndFilterValues(botName, currentConversationID)[field]
    },
    [botName, currentConversationID, getSelectedFormAndFilterValues]
  )

  const getFilterValue = useCallback(
    <K extends keyof FilterValue>(filterName: string, field: K): FilterValue[K] => {
      return getSelectedFormAndFilterValues(botName, currentConversationID)?.filters?.[filterName]?.[field]
    },
    [botName, currentConversationID, getSelectedFormAndFilterValues]
  )

  const handleSubmit = useCallback(
    async (userInput?: string) => {
      setFormErrors(defaultFormErrors)

      // because userQuery might not be updated to the latest value, so take the userInput from chatInput instead
      if ((await validateForm('userQuery', userInput)) && handleChatSubmit) {
        const values = getSelectedFormAndFilterValues(botName, currentConversationID)
        handleChatSubmit(
          { ...values, ...(userInput ? { userQuery: userInput } : {}) },
          {
            saveConversation: getConversationSettings(botName, currentConversationID)?.saveConversation ?? true,
          }
        )
      }
    },
    [
      botName,
      currentConversationID,
      getConversationSettings,
      getSelectedFormAndFilterValues,
      handleChatSubmit,
      validateForm,
    ]
  )

  const reset = useCallback(() => {
    deleteSelectedFilterValues([currentConversationID])
    deleteSelectedFormValues([currentConversationID])
  }, [currentConversationID, deleteSelectedFilterValues, deleteSelectedFormValues])

  const handleSubmitWithFiles = useCallback(
    async (files: File[]) => {
      setFormErrors(defaultFormErrors)
      if (await validateForm()) {
        handleFileSubmit && handleFileSubmit(getSelectedFormAndFilterValues(botName, currentConversationID), files)
      }
    },
    [botName, currentConversationID, getSelectedFormAndFilterValues, handleFileSubmit, validateForm]
  )

  // Before we have messages within a conversation, this loadingText is used if we are in a loading state and passed down to children who require it
  // isLoading is passed down to indicate whether something is in a loading state and is used to determine whether or not to disable certain inputs
  const { isLoading, loadingText } = useMemo(() => {
    let text = null
    let loading = false
    if (isCreatingConversation[botName]) {
      text = t('generic.creatingConversation')
      loading = true
    }
    if (isFetchingConversationData[currentConversationID ?? 'temp']) {
      text = t('generic.fetchingConversationData')
      loading = true
    }
    if (isKBot && isFetchingKBots) {
      text = t('kBots.fetchingKBots')
      loading = true
    }
    return { isLoading: loading, loadingText: text }
  }, [isCreatingConversation, botName, isFetchingConversationData, currentConversationID, isKBot, isFetchingKBots, t])

  // Before we have messages within a conversation, this text is used if we are in an error state and passed down to children who require it
  const { isError, errorText } = useMemo(() => {
    let text = null
    let err = false
    if (isCreatingConversationError[botName] || isFetchingConversationDataError[currentConversationID ?? 'temp']) {
      if (!isCreatingConversationError[botName]) {
        text = t('generic.failedFetchingConversationData')
        err = true
      }

      if (isCreatingConversationError[botName] === 'TOO-MANY-CONVERSATIONS') {
        text = t('generic.tooManyConversations')
        err = true
      }

      text = t('generic.failedCreatingConversation')
      err = true
    }
    if (isKBot && fetchKBotsError === 'fetchKBotsError') {
      text = t('kBots.failedFetchingKBots')
      err = true
    }
    return { isError: err, errorText: text }
  }, [
    isCreatingConversationError,
    botName,
    isFetchingConversationDataError,
    currentConversationID,
    isKBot,
    fetchKBotsError,
    t,
  ])

  return (
    <BotSpecificFormContext.Provider
      value={{
        clearError,
        currentConversationID,
        errorText,
        formErrors,
        getCombinedValues,
        getCombinedValue,
        getFilterValue,
        handleSubmit,
        handleSubmitWithFiles,
        isError,
        isLoading,
        loadingText,
        reset,
        setValue,
      }}
    >
      {children}
    </BotSpecificFormContext.Provider>
  )
}

export const useBotSpecificFormContext = (): BotSpecificFormContextType => useContext(BotSpecificFormContext)
