import { ErrorRes, TRequest, TResponse } from './api.types.js'

export type GetTranslateUrlUploadReqItem = {
  contentType: string
  fileSize: number
  fileName: string
}

export type GetUrlUploadSuccessResItem = {
  url: string
  documentId: string
  fileName: string
}

export interface GetTranslateUrlUploadReq extends TRequest {
  folderName?: string
  files: GetTranslateUrlUploadReqItem[]
}

export type GetTranslateUrlUploadSuccessRes = {
  items: GetUrlUploadSuccessResItem[]
  requestId: string
}

/**
 * possible errors:
 *  1. ContentTypeTypeError: meaning we dont support the file type users have uploaded.
 *  2. FileSizeOverSizeError: meaning user's file is too large
 *  3. FileNameOverLengthError: meaning user's filename doesnt match our standard
 *  4. FileNameInvalidCharError: meaning user's filename containing invalid characters.
 *  5. TooManyFilesError: meaning users submit too many files for translations
 *  6. FolderNameInvalidError: meaning folder name provided by user does not follow our pattern
 *  7. FolderNameOverLengthError: meaning the folder name length is longer than 20 characters (configurable)
 */
export type GetTranslateUrlUploadRes = GetTranslateUrlUploadSuccessRes | ErrorRes

export enum TargetLanguage {
  en = 'en',
  fr = 'fr',
}

export interface TranslateAPIRequest extends TRequest {
  folderId: string
  targetLanguage: TargetLanguage
}

/**
 * expected error:
 * 1. UnsupportedTargetLanguageError: meaning user sends the target language which is not supported by our system now.
 */
export type TranslateAPISuccessResponse = { requestId: string }
export type TranslateAPIResponse = TranslateAPISuccessResponse | ErrorRes

export interface GetFolderViewAPIRequest extends TRequest {
  folderId?: string
}

export type FolderData = {
  status: BatchStatus
  files: string[]
  folderId: string
  createdAt: number
  requestId: string
}
export interface GetFolderViewAPIResponse extends TResponse {
  data: FolderData[]
}

export interface DeleteAPIRequest extends TRequest {
  files: string[]
}

export interface DeleteAPIResponse extends TResponse {
  status: 'ok'
}

export interface ExportTranslationDataAPIRequest extends TRequest {
  files: string[]
}

export type BatchStatus = {
  id: string
  createdDateTimeUtc: string
  lastActionDateTimeUtc: string
  status: string
  error?: {
    code: string
    message: string
    target: string
    innerError: {
      code: string
      message: string
    }
  }
  summary: {
    total: number
    failed: number
    success: number
    inProgress: number
    notYetStarted: number
    cancelled: number
    totalCharacterCharged: number
  }
}
export interface TranslateBatchStatusAPIRequest extends TRequest {
  requestId: string
}

export interface TranslateBatchStatusAPIResponse extends TResponse {
  status: BatchStatus
}
