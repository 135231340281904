import { ReactElement } from 'react'
import { Tooltip, TooltipProps } from '@chakra-ui/tooltip'

import { useThemeContext } from 'providers/ThemeProvider'

import { IconButton } from './IconButton'
import { MenuButton } from './MenuButton'
import { TextButton } from './TextButton'
import { TextIconButton } from './TextIconButton'

type TooltipButtonProps = {
  button:
    | ReactElement<typeof IconButton>
    | ReactElement<typeof MenuButton>
    | ReactElement<typeof TextButton>
    | ReactElement<typeof TextIconButton>
} & Omit<TooltipProps, 'children'>

export const TooltipButton = (props: TooltipButtonProps) => {
  const { isTablet } = useThemeContext()
  const { button, placement = 'top', isDisabled = !isTablet, ...rest } = props

  return (
    <Tooltip
      {...rest}
      className="text-xs text-black bg-kpmgGray5"
      closeOnScroll
      isDisabled={isDisabled}
      openDelay={200}
      placement={placement}
      tabIndex={0}
    >
      {button}
    </Tooltip>
  )
}
