import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Text } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'

import { useAuthContext } from 'providers/AuthProvider'
import { useI18Context } from 'providers/i18Provider'

export const LoginRedirect = () => {
  const { t } = useI18Context()
  const { isAuthenticated, msalInstance } = useAuthContext()
  const navigate = useNavigate()
  const { language } = useI18Context()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  // This timeout is used as a fallback if we get stuck on "/login" where isAuthenticated never gets updated to true
  useEffect(() => {
    const timerId = setTimeout(() => {
      msalInstance.loginRedirect({
        redirectStartPage: '/#/login',
        scopes: [],
        extraQueryParameters: {
          ui_locales: language,
        },
      })
    }, 5000)

    // Clear the timeout if the component unmounts before 5 seconds
    return () => clearTimeout(timerId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      className="flex flex-col justify-center flex-grow p-4 bg-center bg-cover bg-overlay md:p-6"
      backgroundImage="linear-gradient(rgba(41, 41, 41, 0.7), rgba(41, 41, 41, 0.7)), url(../images/cube.webp)"
    >
      <Box className="px-12 py-6 text-center text-white rounded-xl max-w-max width-layout backdrop-brightness-75 backdrop-blur">
        <Text as="h1" className="text-2xl font-medium">
          {t('landing.signingIn')}
        </Text>
        <Box className="flex items-center justify-center mt-4">
          <Spinner size="lg" />
        </Box>
      </Box>
    </Box>
  )
}
