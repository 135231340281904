import { KeyboardEvent } from 'react'
import { IconType } from 'react-icons'
import * as BiIcons from 'react-icons/bi'
import { Accordion, AccordionItem, AccordionPanel } from '@chakra-ui/accordion'
import { Image } from '@chakra-ui/image'
import { Box, Text } from '@chakra-ui/layout'
import { StarterPrompt } from '@kleo/types'
import { TFunction } from 'i18next'

import { isLanguagePromptsNull } from 'utils/KBotsUtils'

import { useBotSpecificFormContext } from 'providers/FormProvider'
import { useSettingsContext } from 'providers/SettingsProvider'

import { PromptLibraryProperty } from 'types/types'

import { useI18Context } from '../providers/i18Provider'

import { KleoAccordionButton as AccordionButton } from './buttons/AccordionButton'
import { Link } from './Link'

type PromptLibraryProps = {
  botName: string
  isReadOnly?: boolean
} & (
  | {
      promptLibraryArray?: never
      isKBot: false
      kBotIcon?: never
    }
  | {
      promptLibraryArray: StarterPrompt[]
      isKBot: true
      kBotIcon?: string
    }
)

export const PromptLibrary = (props: PromptLibraryProps) => {
  const { botName, isKBot, isReadOnly = false, promptLibraryArray, kBotIcon } = props
  const { getTForNS, languageAbbreviation, t, doesTranslationExist } = useI18Context()
  const botT = getTForNS(botName.toLocaleLowerCase())

  const iconLibrary = BiIcons as Record<string, IconType>

  const promptLibrary = isKBot
    ? promptLibraryArray
    : doesTranslationExist('promptLibrary', botName.toLocaleLowerCase())
      ? (botT('promptLibrary', { returnObjects: true }) as PromptLibraryProperty[])
      : []

  const accordionClass = 'bg-gray-800 backdrop-blur bg-opacity-[.3] rounded-md first:border-t-0 last:border-b-0 w-full'
  const accordionPanelClass = 'grid grid-cols-1 gap-2 pb-4 xs:grid-cols-2 xl:grid-cols-3'

  // hide accordion if no prompts / sub-prompts for language
  if (isLanguagePromptsNull(languageAbbreviation, promptLibrary)) {
    return null
  } else if (Array.isArray(promptLibrary) && promptLibrary.length) {
    return (
      <>
        {!isReadOnly && <Box className="h-[1px] w-[75%] md:w-[50%] mx-auto my-4 md:my-8 bg-white bg-opacity-75" />}
        <Box className="flex flex-col items-center">
          {!isKBot && !isReadOnly && (
            <Box className="mb-4 bg-gray-800 backdrop-blur bg-opacity-[.3] px-2 md:px-3 py-1 rounded-md inline-block">
              <Text tabIndex={0} className="text-xs font-bold md:text-sm">
                {t('promptLibrary.linkVerbiage')}{' '}
                <Link
                  classToAdd="text-darkLink hover:text-darkLinkHover"
                  external
                  name={t('promptLibrary.library')}
                  url="https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-NMC-Kleo/SitePages/Kleo-prompt-library.aspx"
                />
              </Text>
            </Box>
          )}
          <Accordion allowToggle className={accordionClass}>
            {promptLibrary.map((promptItem, promptLibraryIndex: number) => {
              // Always prioritize using promptItem.icon as the icon for the PromptLibrary. Otherwise if we are on a K-Bot and promptItem.icon isn't defined, default to using the kBotIcon
              const PromptBiIcon =
                promptItem.icon && promptItem.icon !== ''
                  ? iconLibrary[promptItem.icon]
                  : isKBot && kBotIcon && kBotIcon !== ''
                    ? iconLibrary[kBotIcon]
                    : undefined
              const category =
                typeof promptItem.category === 'string'
                  ? promptItem.category
                  : promptItem.category[languageAbbreviation]
              const label = isKBot ? category : botT(category)
              return (
                <AccordionItem key={`${promptLibraryIndex}_${category}`}>
                  <AccordionIconOrImageButton BiIcon={PromptBiIcon} label={label} isReadOnly={isReadOnly} />
                  <AccordionPanel className={`${'prompts' in promptItem && accordionPanelClass}`}>
                    {'prompts' in promptItem ? (
                      promptItem.prompts.map((promptValue, promptIndex: number) => {
                        const prompt = typeof promptValue === 'string' ? promptValue : promptValue[languageAbbreviation]
                        return prompt ? (
                          <AccordionPrompts
                            botT={botT}
                            index={promptIndex}
                            isReadOnly={isReadOnly}
                            key={`${promptLibraryIndex}_${prompt}_${promptIndex}`}
                            prompt={prompt}
                          />
                        ) : null
                      })
                    ) : (
                      <Accordion allowToggle className={accordionClass}>
                        {promptItem.subPrompts.map((subPromptItem, subPromptIndex: number) => {
                          const SubPromptBiIcon =
                            subPromptItem.icon && subPromptItem.icon !== ''
                              ? iconLibrary[subPromptItem.icon]
                              : undefined
                          const subPromptCategory =
                            typeof subPromptItem.category === 'string'
                              ? subPromptItem.category
                              : subPromptItem.category[languageAbbreviation]
                          const subPromptLabel = isKBot ? subPromptCategory : botT(subPromptCategory)

                          return (
                            <AccordionItem key={`${subPromptIndex}_${subPromptCategory}`}>
                              <AccordionIconOrImageButton
                                BiIcon={SubPromptBiIcon}
                                label={subPromptLabel}
                                isReadOnly={isReadOnly}
                              />
                              <AccordionPanel className={accordionPanelClass}>
                                {subPromptItem.prompts.map((subPromptValue, promptIndex: number) => {
                                  const subPrompt =
                                    typeof subPromptValue === 'string'
                                      ? subPromptValue
                                      : subPromptValue[languageAbbreviation]
                                  return subPrompt ? (
                                    <AccordionPrompts
                                      botT={botT}
                                      index={promptIndex}
                                      isReadOnly={isReadOnly}
                                      key={`${promptLibraryIndex}_${subPrompt}_${promptIndex}`}
                                      prompt={subPrompt}
                                    />
                                  ) : null
                                })}
                              </AccordionPanel>
                            </AccordionItem>
                          )
                        })}
                      </Accordion>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              )
            })}
          </Accordion>
        </Box>
      </>
    )
  }
  return null
}

const AccordionPrompts = (props: {
  botT: TFunction<'translation', undefined>
  index: number
  isReadOnly: boolean
  prompt: string
}) => {
  const { botT, index, isReadOnly, prompt } = props
  const { setValue } = useBotSpecificFormContext()
  const valueToSet = botT(prompt)

  return (
    <Box
      key={`${index}_${prompt}`}
      tabIndex={0}
      className={`relative px-2 py-1 text-black border rounded-br-none rounded-xl bg-kpmgGray5 ${!isReadOnly && 'hover:bg-kpmgGray4 hover:border-kpmgGray4 hover:cursor-pointer '}`}
      onClick={() => !isReadOnly && setValue({ field: 'userQuery', value: valueToSet, shouldValidate: false })}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && !isReadOnly) {
          event.preventDefault()
          setValue({ field: 'userQuery', value: valueToSet, shouldValidate: false })
        }
      }}
    >
      <Text className="pr-4 text-xs">{botT(prompt)}</Text>
      {!isReadOnly && <BiIcons.BiPlus className="absolute right-0.5 bottom-0.5" />}
    </Box>
  )
}

const AccordionIconOrImageButton = (props: { BiIcon: IconType | undefined; label: string; isReadOnly: boolean }) => {
  const { BiIcon, label, isReadOnly } = props

  const { isLightMode } = useSettingsContext()

  return BiIcon ? (
    <AccordionButton Icon={BiIcon} label={label} iconClass="mr-2 text-lg md:text-xl" />
  ) : (
    <AccordionButton
      disableFullScreenMode
      Image={
        <Image
          src={
            isReadOnly
              ? isLightMode
                ? '../images/robotBlack.png'
                : '../images/robotWhite.png'
              : '../images/robotWhite.png'
          }
          alt="Robot"
          className="w-4 h-4 mr-2 md:w-5 md:h-5"
        />
      }
      label={label}
    />
  )
}
