import { forwardRef } from 'react'
import { IconButton as ChakraIconButton, IconButtonProps as ChakraIconButtonProps } from '@chakra-ui/button'
import { MenuButton as ChakraMenuButton, MenuButtonProps as ChakraMenuButtonProps } from '@chakra-ui/menu'

import { useSettingsContext } from 'providers/SettingsProvider'

import { CustomButtonProps } from 'types/types'

type MenuButtonProps = CustomButtonProps & ChakraMenuButtonProps & ChakraIconButtonProps

export const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>((props, ref) => {
  const { as = ChakraIconButton, className: classNameToAdd, variant, rounded = 'lg', ...rest } = props
  const { isLightMode } = useSettingsContext()

  const isLightButton =
    variant === 'kpmgWhite' ||
    variant === 'blackAlpha' ||
    (isLightMode && (variant === 'outline' || variant === 'ghost'))

  return (
    <ChakraMenuButton
      {...rest}
      as={as}
      className={`shadow-none ${isLightButton ? 'focus-visible:ring-light' : 'focus-visible:ring-dark'} ${classNameToAdd}`}
      ref={ref}
      rounded={rounded}
      tabIndex={0}
      variant={variant}
    />
  )
})