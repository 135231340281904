import { useCallback, useMemo } from 'react'
import { Heading, Text } from '@chakra-ui/layout'
import { API } from '@kleo/types'

import { TemperatureSelection } from 'components/settings/TemperatureSelection'

import { useI18Context } from 'providers/i18Provider'

import { GetKBotFormValue, KBotFormState, SetKBotFormValue } from 'types/types'

type KBotTemperatureProps = {
  getKBotFormValue: GetKBotFormValue
  setKBotFormValue: SetKBotFormValue
  shouldInputsBeDisabled: boolean
  state: KBotFormState
}

export const KBotTemperature = ({
  getKBotFormValue,
  setKBotFormValue,
  shouldInputsBeDisabled,
  state,
}: KBotTemperatureProps) => {
  const { t } = useI18Context()

  const handleTemperatureSelect = useCallback(
    (temp: API.OpenAITemperature) => {
      setKBotFormValue('temperature', temp, state)
    },
    [setKBotFormValue, state]
  )

  const temperatureValue = useMemo(() => getKBotFormValue('temperature', state), [getKBotFormValue, state])

  return (
    <>
      <Heading as="h2" size="sm" className="mb-2">
        {t('settings.temperature')}{' '}
        <Text as="span" className="text-red-600">
          *
        </Text>
      </Heading>
      <TemperatureSelection
        handleTemperatureSelect={handleTemperatureSelect}
        isDisabled={shouldInputsBeDisabled}
        selectedTemperatureOption={temperatureValue}
      />
    </>
  )
}
