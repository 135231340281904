import { type ThemeConfig } from '@chakra-ui/theme'
import { extendTheme } from '@chakra-ui/theme-utils'

const config: ThemeConfig = {
  // Set the initial color mode to 'dark'
  initialColorMode: 'dark',
  // Disable the use of the system color mode
  useSystemColorMode: false,
}

const theme = extendTheme({
  config,
  components: {
    Button: {
      variants: {
        kpmgWhite: {
          bg: 'white',
          color: '#1F2937', // gray-800
          _hover: {
            bg: '#F2F2F2', // whiteHover
            _disabled: {
              bg: 'white', // Ensure it stays the same as the initial bg
            },
          },
        },
        kpmgWhiteBlueText: {
          bg: 'white',
          color: '#02338D', // kpmgBlue
          _hover: {
            bg: '#F2F2F2', // whiteHover
            _disabled: {
              bg: 'white', // Ensure it stays the same as the initial bg
            },
          },
        },
        kpmgBlue: {
          bg: '#02338D', // kpmgBlue
          color: 'white',
          _hover: {
            bg: '#033FB0', // kpmgBlueHover
            _disabled: {
              bg: '#02338D', // Ensure it stays the same as the initial bg
            },
          },
        },
        kpmgDarkBlue: {
          bg: '#0C233C', // kpmgDarkBlue
          color: 'white',
          _hover: {
            bg: '#0F2B49', // kpmgDarkBlueHover
            _disabled: {
              bg: '#0C233C', // Ensure it stays the same as the initial bg
            },
          },
        },
        kpmgCobaltBlue: {
          bg: '#1E4AE2', // kpmgCobaltBlue
          color: 'white',
          _hover: {
            bg: '#1A41CB', // kpmgCobaltBlueHover
            _disabled: {
              bg: '#1E4AE2', // Ensure it stays the same as the initial bg
            },
          },
        },
        kpmgLightPurple: {
          bg: '#B497FF', // kpmgLightPurple
          color: 'white',
          _hover: {
            bg: '#A480FF', // kpmgLightPurpleHover
            _disabled: {
              bg: '#B497FF', // Ensure it stays the same as the initial bg
            },
          },
        },
        kpmgPurple: {
          bg: '#7213EA', // kpmgPurple
          color: 'white',
          _hover: {
            bg: '#6911D4', // kpmgPurpleHover
            _disabled: {
              bg: '#7213EA', // Ensure it stays the same as the initial bg
            },
          },
        },
        whiteAlpha: {
          bg: '#FFFFFF14', // whiteAlpha
          color: 'white',
          _hover: {
            bg: '#FFFFFF28', // whiteAlphaHover
            _disabled: {
              bg: '#FFFFFF14', // Ensure it stays the same as the initial bg
            },
          },
        },
        blackAlpha: {
          bg: '#00000014', // blackAlpha
          color: '#1F2937',
          _hover: {
            bg: '#00000028', // blackAlphaHover
            _disabled: {
              bg: '#00000014', // Ensure it stays the same as the initial bg
            },
          },
        },
      },
    },
  },
  fonts: {
    heading: 'OpenSansCondensed, ui-sans-serif, system-ui',
    body: 'OpenSans, ui-sans-serif, system-ui',
  },
})

export default theme
