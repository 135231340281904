export const DEFAULT_FILE_NAME = 'download.bin'
export const getFileName = (responseHeaders: Headers): string => {
  const contentDis = responseHeaders.get('Content-Disposition')
  if (contentDis != null) {
    //Simple implementation  of disposition. Assumes our backend api logic to construct the header.
    //Should be removed once we will switch to use browser APi to download file . Browser know how to handle this much better
    const options = contentDis.split(';').map((i) => i.trim())
    const utfFile = options
      .filter((i) => i.startsWith('filename*='))
      .map((i) => {
        const fileName = i.split('=')[1]
        return decodeURIComponent(fileName.substring("UTF-8''".length))
      })
      .join('')

    const normalFile = options
      .filter((i) => i.startsWith('filename='))
      .map((i) => {
        const fileName = i.split('=')[1]
        return fileName.substring(1, fileName.length - 1) //Remove surrounding quotes
      })
      .join('')
    if (utfFile && utfFile.length > 0) {
      return utfFile
    } else if (normalFile && normalFile.length > 0) {
      return normalFile
    } else {
      console.log('No file not utf file')
      console.log(JSON.stringify({ responseHeaders, options, utfFile, normalFile }, null, 4))
      return DEFAULT_FILE_NAME
    }
  } else {
    console.log('No Content-Disposition')
    console.log('No file not utf file', JSON.stringify(responseHeaders, null, 4))
    return DEFAULT_FILE_NAME
  }
}
