import { useCallback, useMemo } from 'react'
import { Navigate, Route, Routes as RouterRoutes, useLocation, useNavigate } from 'react-router-dom'
import { Box, Text } from '@chakra-ui/layout'
import { Menu, MenuItem, MenuList } from '@chakra-ui/menu'
import { Portal } from '@chakra-ui/portal'

import { MenuButton } from 'components/buttons/MenuButton'

import { useSettingsContext } from 'providers/SettingsProvider'
import { useThemeContext } from 'providers/ThemeProvider'

import { TabsType } from 'types/types'

import { Tab } from '../tabs/Tabs'

type KBotsTabsProps = {
  tabSections: TabsType[]
}

export const KBotsTabPanels = ({ tabSections }: KBotsTabsProps) => {
  const location = useLocation()

  return (
    <Box
      className={`flex-grow h-full border border-white rounded-b-md ${location.pathname === '/k-bots/createKBot' || location.pathname === '/k-bots/editKBot' ? 'overflow-hidden' : 'overflow-x-hidden overflow-y-auto'}`}
    >
      <RouterRoutes>
        <Route path="/" element={<Navigate to="viewKBots" />} />
        {tabSections.map((tabSection) => {
          return (
            <Route
              key={tabSection.route}
              path={tabSection.route}
              element={
                <Box className="flex flex-col items-center justify-start h-full p-2">{tabSection.component}</Box>
              }
            />
          )
        })}
      </RouterRoutes>
    </Box>
  )
}

export const KBotsTabs = ({ tabSections }: { tabSections: TabsType[] }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isMobile } = useThemeContext()
  const { isLightMode } = useSettingsContext()

  const handleTabClick = useCallback(
    (route: string) => {
      navigate(`/k-bots${route}`)
    },
    [navigate]
  )

  const tabs = useMemo(() => {
    if (isMobile) {
      const activeTab =
        tabSections.find((section) => {
          return `/k-bots${section.route}` === location.pathname
        }) ?? tabSections[0]

      return {
        visibleTabs: [activeTab],
        hiddenTabs: tabSections.filter((section) => section.route !== activeTab.route),
      }
    } else {
      return {
        visibleTabs: tabSections,
        hiddenTabs: null,
      }
    }
  }, [isMobile, tabSections, location])

  // TODO - Make this a generic component because AssessmentTabs is very very similar
  return (
    <Box className="flex items-center justify-between text-white">
      <Box className="flex items-end justify-start -mb-[1px] h-full">
        <>
          {tabs.visibleTabs.map((tabSection, tabSectionIndex: number) => (
            <Tab
              handleTabClick={handleTabClick}
              key={`tab-${tabSection.label}-${tabSectionIndex}`}
              parentRouteSection="k-bots"
              tabSection={tabSection}
            />
          ))}
          {tabs.hiddenTabs && (
            <Menu>
              <MenuButton aria-label="other-navigation-options" as={Box} className="h-full">
                <Box className="flex items-center justify-center h-full font-bold px-3 py-1.5 text-sm lg:py-2 lg:px-4 lg:text-base border border-white rounded-t mr-1 cursor-pointer ">
                  <Text>...</Text>
                </Box>
              </MenuButton>
              <Portal>
                <MenuList>
                  {tabs.hiddenTabs.map((tabSection, tabSectionIndex: number) => (
                    <MenuItem
                      onClick={() => handleTabClick(tabSection.route)}
                      className={` ${isLightMode ? 'text-kpmgBlue' : ''}`}
                      key={`tab-hidden-${tabSection.label}-${tabSectionIndex}`}
                    >
                      {tabSection.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Portal>
            </Menu>
          )}
        </>
      </Box>
    </Box>
  )
}
