import { Dispatch, SetStateAction } from 'react'
import { Box, Text } from '@chakra-ui/layout'

import { useFiltersContext } from 'providers/FiltersAndFormsProvider'
import { useI18Context } from 'providers/i18Provider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useSettingsContext } from 'providers/SettingsProvider'
import { useUploadContext } from 'providers/UploadProvider'

import { TextButton } from './buttons/TextButton'
import { ModalBox } from './Modal'

type ConfirmDeleteConversationModalProps = {
  conversationsToDelete: null | string[]
  isOpen: boolean
  onClose: () => void
  showConversationCount?: boolean
  setConversationIDToDelete?: Dispatch<SetStateAction<string | null>>
  botNames: string[]
  onDelete?: () => void
}

export const ConfirmDeleteConversationModal = ({
  botNames,
  conversationsToDelete,
  isOpen,
  onClose,
  onDelete,
  showConversationCount = false,
  setConversationIDToDelete,
}: ConfirmDeleteConversationModalProps) => {
  const { t } = useI18Context()
  const { isLightMode } = useSettingsContext()

  const { deleteConversations } = useMessagesContext()
  const { deleteDocumentContent } = useUploadContext()
  const { deleteSelectedFilterValues, deleteSelectedFormValues } = useFiltersContext()
  return (
    <ModalBox
      isOpen={isOpen}
      modalBody={
        <Box>
          <Text as="h2" className="mb-2 font-bold">
            {t(
              `deleteInstanceModal.areYouSureMessages${conversationsToDelete && conversationsToDelete?.length > 1 ? 'Multi' : ''}`
            )}
          </Text>
          <Text as="p">
            {t(
              `deleteInstanceModal.clearExplanation${conversationsToDelete && conversationsToDelete?.length > 1 ? 'Multi' : ''}`
            )}
          </Text>
          {conversationsToDelete && showConversationCount && (
            <Text as="p" className="my-4">
              {t('deleteInstanceModal.conversationsWillBeDeleted', {
                conversationsCount: conversationsToDelete.length,
              })}
            </Text>
          )}
        </Box>
      }
      modalFooter={
        <Box className="flex flex-wrap gap-3">
          <TextButton aria-label="close" className="ml-auto text-sm md:text-base" id="close-button" onClick={onClose}>
            {t('controls.cancel')}
          </TextButton>
          <TextButton
            aria-label="delete-conversation"
            className="ml-auto text-sm md:text-base"
            id="delete-conversation-button"
            onClick={() => {
              if (conversationsToDelete && conversationsToDelete.length) {
                // Need to delete all values in providers that used to belong to the conversation ID that has now been deleted
                deleteConversations(botNames, conversationsToDelete)
                deleteDocumentContent(botNames, conversationsToDelete)
                deleteSelectedFormValues(conversationsToDelete)
                deleteSelectedFilterValues(conversationsToDelete)
                if (setConversationIDToDelete) {
                  setConversationIDToDelete(null)
                }
                onDelete && onDelete()
              }
              onClose()
            }}
            variant={isLightMode ? 'kpmgCobaltBlue' : 'kpmgWhite'}
          >
            {t(
              `deleteInstanceModal.deleteInstance${conversationsToDelete && conversationsToDelete?.length > 1 ? 'Multi' : ''}`
            )}
          </TextButton>
        </Box>
      }
      modalHeader={t(
        `generic.deleteInstance${conversationsToDelete && conversationsToDelete?.length > 1 ? 'Multi' : ''}`
      )}
      onClose={onClose}
    />
  )
}
