import { ChangeEvent, useCallback, useMemo } from 'react'
import { BiInfoCircle } from 'react-icons/bi'
import { FormLabel } from '@chakra-ui/form-control'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/input'
import { Box, Heading, Stack, StackDivider, Text } from '@chakra-ui/layout'
import { Tooltip } from '@chakra-ui/tooltip'
import { Fade } from '@chakra-ui/transition'
import { VisuallyHidden } from '@chakra-ui/visually-hidden'

import { useI18Context } from 'providers/i18Provider'
import { useKBotContext } from 'providers/KBotsProvider'
import { useSettingsContext } from 'providers/SettingsProvider'

import { KBotFormState, KBotFormValues, SetKBotFormValue, ValidationErrors } from 'types/types'

export const KBotDescriptions = ({
  formErrors,
  maxLength,
  setKBotFormValue,
  shouldInputsBeDisabled,
  state,
}: {
  formErrors: ValidationErrors<KBotFormValues>
  maxLength: number
  setKBotFormValue: SetKBotFormValue
  shouldInputsBeDisabled: boolean
  state: KBotFormState
}) => {
  const { t } = useI18Context()
  const { isLightMode } = useSettingsContext()
  const { getKBotFormValue } = useKBotContext()
  const descriptionValue = useMemo(() => getKBotFormValue('description', state), [getKBotFormValue, state])

  const handleInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>, lang: 'en' | 'fr', index: number) => {
      setKBotFormValue(
        'description',
        (prevDesc) => prevDesc.map((desc, i) => (i === index ? { ...desc, [lang]: e.target.value } : desc)),
        state
      )
    },
    [setKBotFormValue, state]
  )

  return (
    <>
      <Box className="flex items-center justify-start mb-4">
        <Heading as="h2" size="sm" className="mr-2" id="kbot-description-heading">
          {t('kBots.createEdit.describeKBot')}
        </Heading>
        <Tooltip
          className={`text-xs ${isLightMode ? 'text-white' : 'text-black'}`}
          label={
            <>
              <Text>{t('kBots.createEdit.descriptionExplanation')}</Text>
              <Text>{t('kBots.createEdit.descriptionExplanation2')}</Text>
            </>
          }
          placement="right"
          aria-describedby="kbot-description-heading"
        >
          <Fade in>
            <BiInfoCircle className="self-end w-4 h-4 text-gray-600" />
          </Fade>
        </Tooltip>
      </Box>
      {formErrors.description && (
        <Text as="span" className={`block mb-2 text-sm ${isLightMode ? 'text-red-600' : 'text-red-400'}`}>
          {t(formErrors.description)}
        </Text>
      )}
      {descriptionValue.map((description, index) => (
        <Box key={`description-input-${index}`} className="border rounded-md border-kpmgGray3 h-[51px] mb-3">
          <Stack spacing={0} divider={<StackDivider />}>
            <InputGroup size="xs">
              <VisuallyHidden>
                <FormLabel htmlFor={`kbot-description-en-${index}`}>
                  {`${t('kBots.createEdit.languageEN')} ${t('kBots.createEdit.describeKBot')}`}
                </FormLabel>
              </VisuallyHidden>
              <InputLeftAddon className="flex items-center justify-center uppercase rounded-bl-none rounded-tl-md min-w-11">
                en
              </InputLeftAddon>
              <Input
                id={`kbot-description-en-${index}`}
                name="kBotDescription"
                value={description.en ?? ''}
                onChange={(e) => handleInput(e, 'en', index)}
                maxLength={maxLength}
                variant="unstyled"
                className="pl-3"
                placeholder={`${t('kBots.createEdit.typeSomething')} (${t('kBots.createEdit.languageEN')})`}
                isDisabled={shouldInputsBeDisabled}
              />
            </InputGroup>
            <InputGroup size="xs">
              <VisuallyHidden>
                <FormLabel htmlFor={`kbot-description-fr-${index}`}>
                  {`${t('kBots.createEdit.languageFR')} ${t('kBots.createEdit.describeKBot')}`}
                </FormLabel>
              </VisuallyHidden>
              <InputLeftAddon className="flex items-center justify-center uppercase rounded-tl-none rounded-bl-md min-w-11">
                fr
              </InputLeftAddon>
              <Input
                id={`kbot-description-fr-${index}`}
                name="kBotDescription"
                value={description.fr ?? ''}
                onChange={(e) => handleInput(e, 'fr', index)}
                maxLength={maxLength}
                variant="unstyled"
                className="pl-3"
                placeholder={`${t('kBots.createEdit.typeSomething')} (${t('kBots.createEdit.languageFR')})`}
                isDisabled={shouldInputsBeDisabled}
              />
            </InputGroup>
          </Stack>
        </Box>
      ))}
    </>
  )
}
