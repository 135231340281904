import { BiLinkExternal } from 'react-icons/bi'
import { Link as ReactRouterDomLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/layout'

import { useSettingsContext } from '../providers/SettingsProvider'

// A user can optionally define either a "classToAdd" or a "defaultStyle", never both
export type LinkProps =
  | {
      classToAdd?: never
      defaultStyle?: boolean
      external?: boolean
      name: string
      url: string
    }
  | {
      classToAdd?: string
      defaultStyle?: never
      external?: boolean
      name: string
      url: string
    }

export const FooterLink = (props: LinkProps) => {
  const { name, url, external = false } = props
  return (
    <ChakraLink isExternal={external} href={url} className="mb-1 text-xs hover:underline">
      {name}
      {external && <BiLinkExternal className="inline-block ml-1" />}
    </ChakraLink>
  )
}

export const Link = (props: LinkProps) => {
  const { name, url, external = false, defaultStyle, classToAdd } = props
  const { isLightMode } = useSettingsContext()

  const linkClass = defaultStyle
    ? isLightMode
      ? 'text-lightLink hover:text-lightLinkHover'
      : 'text-darkLink hover:text-darkLinkHover'
    : classToAdd || '' // Use classToAdd if defined, otherwise an empty string

  return (
    <ChakraLink isExternal={external} href={url} className={`underline ${linkClass}`}>
      {name}
      {external && <BiLinkExternal className="inline-block ml-1" />}
    </ChakraLink>
  )
}

export const ReactRouterLink = (props: LinkProps) => {
  const { name, url, external = false, defaultStyle, classToAdd } = props
  const { isLightMode } = useSettingsContext()

  const linkClass = defaultStyle
  ? isLightMode
    ? 'text-lightLink hover:text-lightLinkHover'
    : 'text-darkLink hover:text-darkLinkHover'
  : classToAdd || ''

  return (
    <ChakraLink
      as={ReactRouterDomLink}
      isExternal={external}
      to={url}
      className={`mb-4 underline min-w-min ${linkClass}`}
    >
      {name}
      {external && <BiLinkExternal className="inline-block ml-1" />}
    </ChakraLink>
  )
}
