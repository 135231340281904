async function importIfValid<T extends object>(filePath: string): Promise<T> {
  try {
    const response = await fetch(filePath)
    // check if file exist
    if (!response.ok) {
      return {} as T
    }

    // check if file is non-empty
    const fileContent = await response.text()
    if (fileContent.trim().length === 0) {
      return {} as T
    }

    const module = (await import(/* webpackIgnore: true */ filePath)) as T
    return module
  } catch (error) {
    return {} as T
  }
}

export { importIfValid }
